import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'


const card_style = {
    backgroundColor: '#F5F5F5',
    color: 'black',
    padding: '1px 16px'
}

export default function Code({ code }) {
    return (
        <Card>
            <CardContent style={card_style}>
                <pre>{code}</pre>
            </CardContent>
        </Card>
    )
}