import React from 'react'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Code from '../components/Code'

import { themeLight } from '../theme'

const item = {
    background: '#FFE5E5',
    height: 100,
}

function Grids() {
    const background = themeLight.palette.background.default

    return (
        <Container maxWidth={false}>
            <Typography variant='title1'>Grids</Typography>

            <div style={{ position: 'relative', height: 200, marginTop: 30 }}>
                <div style={{ background: '#FE500055', width: 196, height: 196,
                        position: 'absolute', top:0, left:0 }} />
                <div style={{ background: '#FE500055', width: 160, height: 160,
                        position: 'absolute', top:0, left:0 }} />
                <div style={{ background: '#FE500055', width: 128, height: 128,
                        position: 'absolute', top:0, left:0 }} />
                <div style={{ background: '#FE500055', width: 96, height: 96,
                        position: 'absolute', top:0, left:0 }} />
                <div style={{ background: '#FE500055', width: 64, height: 64,
                        position: 'absolute', top:0, left:0 }} />
            </div><br/>
            <p style={{ width: '64%' }}>
                Grids are important when setting up your design for our digital interfaces. They are the base of every experience and help in guiding design factors such as imagery, buttons, and other design components.
            </p>
            <a target='_blank' rel='noreferrer'
                    href='https://www.figma.com/file/iDYRF013idb4Cu8v5ONvWv/Podium-DS-Guidelines?node-id=0%3A15941'>
                <Button variant='contained'>View Guidelines</Button>
            </a>

            <div style={{ background: themeLight.palette.background.paper,
                            padding: 100, marginTop: 30 }}>
                <pre><a href='https://next.material-ui.com/components/grid/'>Material UI Grids</a></pre>
                <Code code={`import Grid from '@material-ui/core/Grid'

const item = {                  // for the pink grids displayed below
    background: '#FFE5E5',
    height: 100,
}`}/>

                <p style={{ marginTop: 50 }}>Mobile Grids - 4 Column</p>
                <hr/>
                <div style={{width: 100, display: 'inline-block', marginRight: 25}}>
                    <p>100px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                    </Grid>
                </div>
                <div style={{width: 120, display: 'inline-block', marginRight: 25}}>
                    <p>120px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                    </Grid>
                </div>
                <div style={{width: 150, display: 'inline-block', marginRight: 25}}>
                    <p>150px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                    </Grid>
                </div>
                <div style={{width: 200, display: 'inline-block'}}>
                    <p>200px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                        <Grid item xs={3}><div style={item}/></Grid>
                    </Grid>
                </div><br/><br/>
                <Code code={`const item = {
    background: '#FFE5E5',
    height: 100,
}

<div style={{width: 200, display: 'inline-block'}}>
    <p>200px</p>
    <Grid container spacing={1}>
        <Grid item xs={3}><div style={item}/></Grid>
        <Grid item xs={3}><div style={item}/></Grid>
        <Grid item xs={3}><div style={item}/></Grid>
        <Grid item xs={3}><div style={item}/></Grid>
    </Grid>
</div>`}/><br/>

                <p>Desktop Grids - 12 Column</p>
                <hr/>
                <div style={{width: 200, display: 'inline-block', marginRight: 25}}>
                    <p>200px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                    </Grid>
                </div>
                <div style={{width: 240, display: 'inline-block', marginRight: 25}}>
                    <p>240px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                    </Grid>
                </div>
                <div style={{width: 280}}>
                    <p>280px</p>
                    <Grid container spacing={1} style={{ background }}>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                        <Grid item xs={1}><div style={item}/></Grid>
                    </Grid>
                </div><br/><br/>

                <Code code={`<div style={{width: 280}}>
    <p>280px</p>
    <Grid container spacing={1} }}>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
        <Grid item xs={1}><div style={item}/></Grid>
    </Grid>
</div>`}/>
            </div>
        </Container>
    )
}

export default Grids
