import React from 'react'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Code from '../components/Code'
/*
import { Activity, Analytics, Bag, Bookmark, Calendar, Club, Discover,
    Favorite, Feed, Home, ImageNoImage, Inbox, Menu, Message, NikeApp,
    Profile, Programs, Run, Runner, Search, Settings, ShopNikeApp, Workouts }
    from '@nike/nike-design-system-icons'
*/
import { Navigation, Search } from '@nike/nike-design-system-icons'

import {themeLight} from '../theme'
const colors = themeLight.palette.common
const title_ = {color: colors.grey1}

const useStyles = makeStyles(theme => ({
    root: {
        "&.MuiInputBase-root": {
          color: colors.grey1
        }
    }
}))

function TextFields() {
    const classes = useStyles()

    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>Text Field</Typography>
            <div style={{ height: 300, position: 'relative', marginTop: 30 }}>
                <div style={{ width: 200, height: 40, position: 'absolute',
                                backgroundColor: colors.grey1,
                                borderRadius: 8 }}/>
                <div style={{ width: 200, height: 150,
                                position: 'absolute', top: 44,
                                backgroundColor: colors.grey3,
                                borderRadius: 8 }}/>
                <div style={{ width: 150, height: 40,
                                position: 'absolute', top: 50, left: 220,
                                backgroundColor: colors.grey1,
                                borderRadius: 8 }}/>
                <div style={{ width: 150, height: 150,
                                position: 'absolute', top: 94, left: 220,
                                backgroundColor: colors.grey3,
                                borderRadius: 8 }}/>
            </div>
            <p style={{ width: '64%', marginBottom: 80 }}>
                Text fields allow users to enter text into a UI. They typically appear in forms and dialogs used within our Nike Digitial experiences.
            </p>
            <a target='_blank' rel='noreferrer'
                href='https://www.figma.com/file/iDYRF013idb4Cu8v5ONvWv/Podium-DS-Guidelines?node-id=4154%3A4128'>
            <Button variant='contained' size='large'>View Guidelines</Button>
            </a>

            <div style={{ height: 100 }}/>
            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Text Field <span style={title_}>/ Dropdown</span>
            </Typography>
            <Code code="import TextField from '@material-ui/core/TextField'" />
            <div style={{ height: 40 }}/>
            <Typography variant='title3' component='h1'>Dropdown</Typography>
            <hr/>
            <Typography variant='legal1' component='p' color={colors.grey1}>
                Collapsed Variants
            </Typography>

            <Grid container columns={2} spacing={5}>
            <Grid item xs={1}>
                    <TextField select value='1' InputProps={{ className: classes.root }}>
                        <MenuItem value='1'>Lorem Ipsum</MenuItem>
                    </TextField>
                    <Code code={`
<TextField select value='1'
        InputProps={{ className: classes.root }}>
    <MenuItem value='1'>Lorem Ipsum</MenuItem>
</TextField>`}/>
                </Grid>
                <Grid item xs={1}>
                    <TextField select value='1' InputProps={{ className: classes.root }}>
                        <MenuItem value='1'>Lorem Ipsum</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <TextField select value='1' error helperText='Helper Text'>
                        <MenuItem value='1'>Lorem Ipsum</MenuItem>
                    </TextField>
                    <Code code={`
<TextField select value='1' error
            helperText='Helper Text'>
    <MenuItem value='1'>Lorem Ipsum</MenuItem>
</TextField>`}/>
                </Grid>
                <Grid item xs={1}>
                    <TextField select value='1' error helperText='Helper Text'>
                        <MenuItem value='1'>Lorem Ipsum</MenuItem>
                    </TextField>
                </Grid>
            </Grid>

            <div style={{ height: 100 }}/>
            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Text Field <span style={title_}>/ Search</span>
            </Typography>
            <div style={{ height: 40 }}/>
            <Typography variant='title3' component='h1'>Search</Typography>
            <hr/>
            <Code code="import { Search, Navigation } from '@nike/nike-design-system-icons'"/>
            <div style={{ height: 40 }}/>
            <Typography variant='legal1' component='p' color={colors.grey1}>
                Variants
            </Typography>

            <Grid container columns={3} spacing={5}>
                <Grid item xs={1}>
                    <TextField value='Lorem Ipsum'
                        InputProps={{
                            startAdornment: <Search/>,
                            endAdornment: <Navigation/>,
                        }} />
                    <Code code={`
<TextField value='Lorem Ipsum'
    InputProps={{
    startAdornment: <Search/>,
    endAdornment: <Navigation/>,
    }} />`}/>

                    <div style={{ height: 64 }}/>
                    <TextField value='Lorem Ipsum'
                        label='Label Text'
                        InputProps={{
                            startAdornment: <Search/>,
                            endAdornment: <Navigation/>,
                        }} />
                    <Code code={`
<TextField value='Lorem Ipsum'
    label='Label Text'
    InputProps={{
    startAdornment: <Search/>,
    endAdornment: <Navigation/>,
    }} />`}/>

                    <div style={{ height: 64 }}/>
                    <TextField value='Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor.'
                        label='Label Text'
                        InputProps={{
                            startAdornment: <Search/>,
                            endAdornment: <Navigation/>,
                        }} />
                </Grid>
                <Grid item xs={1}>
                    <TextField select value='1' InputProps={{ className: classes.root }}>
                        <MenuItem value='1'>Lorem Ipsum</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <TextField select value='1' InputProps={{ className: classes.root }}>
                        <MenuItem value='1'>Lorem Ipsum</MenuItem>
                    </TextField>
                </Grid>
            </Grid>

        </Container>
    )
}

export default TextFields
