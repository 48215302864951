import React from 'react'

import Container from '@material-ui/core/Container'

function Mui() {
    return (
        <Container maxWidth={false}>
            <h1>About Material UI (Mui)</h1>

            <h4>Why use Material UI?</h4>
            <p>
                @material-ui/core is the most popular library for React UI,
                with <a href='https://npm-stat.com/charts.html?package=%40material-ui%2Fcore&from=2021-01-01'>over 400,000 downloads per day</a>
            </p>
            <p>
                Most developers at Nike already have experience with Mui,
                on both desktop and mobile user interfaces.
            </p>
            <p>
                Podium Design System has many features that are already in Mui
                including On Light and On Dark backgrounds, Accessibility properties,
                buttons with Active and Disabled states,
            </p>
            <p>
                Figma uses styling components that are in Mui,
                including Typography, Text Styles and Color Styles.
            </p>
            <p>
                Most of the components listed in the Podium DS are supplied by Mui,
                and they are easily styled to match Podium using the Theming features.
            </p>

            <br/>
            <h4>Other Examples</h4>
            <p>Check out this example of <a href='https://deprecate.mui-treasury.com/brands/peapods' target='_blank' rel='noreferrer'>customization</a></p>
            <p>Material UI Treasury contains sample <a href='https://mui-treasury.com/components/' target='_blank' rel='noreferrer'>components</a> </p>

        </Container>
    )
}

export default Mui
