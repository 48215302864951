import React from 'react'

import MuiAvatar from '@material-ui/core/Avatar'

const sizes = {
    xl: 84,
    l:  64,
    m:  48,
    s:  36,
    xs: 24,
}

export default function Avatar({ size, color, style={} }) {
    const diameter = sizes[size] || sizes.m
    const sx = { width: diameter, height: diameter,  bgcolor: color || '#888'}
    return <MuiAvatar sx={sx} style={style} />
}
