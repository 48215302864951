import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'

const card_style = {
    backgroundColor: '#F5F5F5',
    color: 'black',
    padding: '1px 16px'
}

export default function Implement() {
    return (
        <Container maxWidth={false}>
            <h1>Implementation</h1>
            In the Node.js package.json file, include
            <Card>
                <CardContent style={card_style}>
<pre>{`
"dependencies": {
    "@emotion/react": "11.4.1"
    "@emotion/styled": "11.3.0",
    "@material-ui/core": "5.0.0-alpha.33",
    "@material-ui/icons": "5.0.0-alpha.15",
    "@nike/nike-design-system-icons": "0.3.6",
    "auto-bind": "4.0.0",
    "react": "17.0.2",
    "react-dom": "17.0.2",
    "react-router-dom": "5.2.0",
    "react-scripts": "4.0.3",
    "react-swipeable": "6.2.0",
    "web-vitals": "2.1.0"
},
`}</pre>
                </CardContent>
            </Card>
            <br/><br/>

            Inside of the App.js component, include
            <Card>
                <CardContent style={card_style}>
<pre>{`
import { themeLight } from './components/theme'

function App() {
  return (
    <ThemeProvider theme={themeLight}>
        <BrowserRouter>
            <Security oktaAuth={oktaAuth}>
                <Switch>
                    <Route path='/login/callback' component={LoginCallback} />
                    <SecureRoute exact path='/' component={Home} />
                    <SecureRoute exact path='/about/mui' component={Mui} />
                    ...
                </Switch>
            </Security>
        </BrowserRouter>
    </ThemeProvider>
  )
}
`}</pre>
                </CardContent>
            </Card>
            <br/><br/>

            Inside of page components, include
            <Card>
                <CardContent style={card_style}>
<pre>{`
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
...

import {themeLight} from './theme'
const colors = themeLight.palette.common
`}</pre>
                </CardContent>
            </Card>

        </Container>
    )
}
