import React from 'react'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'

import './App.css'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import { themeLight } from './theme'
import Home from './pages/Home'
import Mui from './pages/About/Mui'
import Implement from './pages/About/Implement'
import Theme from './pages/About/Theme'
import Color from './pages/Color'
import Grids from './pages/Grids'
import Spacing from './pages/Spacing'
import Typog from './pages/Typog'
import Ratios from './pages/Ratios'
import Radii from './pages/Radii'
import Avatars from './pages/Avatars'
import Buttons from './pages/Buttons'
import ContentCards from './pages/ContentCards'
import PromoCards from './pages/PromoCards'
import ProductCards from './pages/ProductCards'
import Carousels from './pages/Carousels'
import Lists from './pages/Lists'
import Accordions from './pages/Accordions'
import Headers from './pages/Headers'
import LocalNav from './pages/LocalNav'
import Snackbars from './pages/SnackBars'
import GlobalNav from './pages/GlobalNav'
import TextFields from './pages/TextFields'

import Swoosh from './swoosh-black.svg'

import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
const isProd = true
const oktaAuthConfig = {    // PKCE authorization flow
    clientId: 'nike.niketech.cx-dev',
    issuer: isProd
            ? 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7'
            : 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    redirectUri: window.location.origin + '/login/callback',
    // https://support.okta.com/help/s/article/Signature-Validation-Failed-on-Access-Token?language=en_US
    ignoreSignature: true,  // this prevents the exception "The key id, ..., was not found in the server's keys"
}
const oktaAuth = new OktaAuth(oktaAuthConfig)


const drawerWidth = 200

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#f5f5f5',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
}))


function App() {
  const classes = useStyles()

  return (
    <ThemeProvider theme={themeLight}>
      <BrowserRouter>
      <Security oktaAuth={oktaAuth}>

        <div className={classes.root}>
          <Drawer className={classes.drawer} variant='permanent'
                  classes={{ paper: classes.drawerPaper }}>
            <div style={{ padding: 32 }}>
              <Link to='/' style={{ color: 'black', textDecoration: 'none' }}>
                <img src={Swoosh} alt='Swoosh'
                    style={{ width: '32px', marginTop: '10px', display: 'inline-block' }}/>
                <span style={{ fontSize: '24px' }}> P.</span>
                <p>Material UI</p>
              </Link>

              <nav style={{ marginTop: 32 }}>
                  <p className='drawerSection'>About</p>
                  <p><Link to='/about/mui'>Material UI</Link></p>
                  <p><Link to='/about/implement'>Implementation</Link></p>
                  <p><Link to='/about/themes'>Themes</Link></p>
                  <p className='drawerSection'>Foundations</p>
                  <p><Link to='/color'>Color</Link></p>
                  <p><Link to='/grids'>Grids</Link></p>
                  <p><Link to='/spacing'>Spacing</Link></p>
                  <p><Link to='/typography'>Typography</Link></p>
                  <p><Link to='/ratios'>Ratios</Link></p>
                  <p><Link to='/radii'>Radii</Link></p>
                  <p className='drawerSection'>Components</p>
                  <p><Link to='/avatars'>Avatars</Link></p>
                  <p><Link to='/buttons'>Buttons</Link></p>
                  <p><Link to='/contentcards'>Content Cards</Link></p>
                  <p><Link to='/promocards'>Promo Cards</Link></p>
                  <p><Link to='/productcards'>Product Cards</Link></p>
                  <p><Link to='/carousels'>Carousel</Link></p>
                  <p><Link to='/lists'>Lists</Link></p>
                  <p><Link to='/accordions'>Accordions</Link></p>
                  <p><Link to='/headers'>Headers</Link></p>
                  <p><Link to='/localnavigation'>Local Navigation</Link></p>
                  <p><Link to='/snackbars'>Snackbars</Link></p>
                  <p><Link to='/globalnavigation'>Global Navigation</Link></p>
                  <p><Link to='/textfield'>Text Field</Link></p>
              </nav>
            </div>
          </Drawer>

          <main className={classes.content} id='main'>
            <Switch>
              <Route path='/login/callback' component={LoginCallback} />
              <SecureRoute exact path='/' component={Home} />
              <SecureRoute exact path='/about/mui' component={Mui} />
              <SecureRoute exact path='/about/implement' component={Implement} />
              <SecureRoute exact path='/about/themes' component={Theme} />
              <SecureRoute exact path='/color' component={Color} />
              <SecureRoute exact path='/grids' component={Grids} />
              <SecureRoute exact path='/spacing' component={Spacing} />
              <SecureRoute exact path='/typography' component={Typog} />
              <SecureRoute exact path='/ratios' component={Ratios} />
              <SecureRoute exact path='/radii' component={Radii} />
              <SecureRoute exact path='/avatars' component={Avatars} />
              <SecureRoute exact path='/buttons' component={Buttons} />
              <SecureRoute exact path='/contentcards' component={ContentCards} />
              <SecureRoute exact path='/promocards' component={PromoCards} />
              <SecureRoute exact path='/productcards' component={ProductCards} />
              <SecureRoute exact path='/carousels' component={Carousels} />
              <SecureRoute exact path='/lists' component={Lists} />
              <SecureRoute exact path='/accordions' component={Accordions} />
              <SecureRoute exact path='/headers' component={Headers} />
              <SecureRoute exact path='/localnavigation' component={LocalNav} />
              <SecureRoute exact path='/snackbars' component={Snackbars} />
              <SecureRoute exact path='/globalnavigation' component={GlobalNav} />
              <SecureRoute exact path='/textfield' component={TextFields} />
            </Switch>
          </main>
        </div>
      </Security>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
