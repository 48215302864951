import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { CaretDown, Favorite, PlayFilled } from '@nike/nike-design-system-icons'

import Code from '../components/Code'
import {themeLight, themeDark} from '../theme'

const label_style = {color: '#2EA4FF'}
const title_ = {color: themeLight.palette.common.grey1}


function Buttons() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Buttons <span style={title_}>/ Large</span>
            </Typography>

            <Code code={`import Button from '@material-ui/core/Button'
import { CaretDown, Favorite, PlayFilled } from '@nike/nike-design-system-icons'`}/>

            <Typography variant='title3' component='p' style={{ marginTop: 36 }}>Primary</Typography><br/>
            <Grid container columns={2} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='large'>
                        Button
                    </Button>
                    <Code code={`<Button variant='contained' size='large'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='large' disabled>
                        Button
                    </Button>
                    <Code code={`<Button variant='contained' size='large' disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='large'>
                        Button <CaretDown style={{ marginLeft: 8 }}/>
                    </Button>
                    <Code code={`<Button variant='contained' size='large'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='large' disabled>
                        Button <CaretDown style={{ marginLeft: 8 }}/>
                    </Button>
                    <Code code={`<Button variant='contained' size='large' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='large'>
                        <Favorite style={{ marginRight: 8 }}/> Button
                    </Button>
                    <Code code={`<Button variant='contained' size='large'>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='large' disabled>
                        <Favorite style={{ marginRight: 8 }}/> Button
                    </Button>
                    <Code code={`<Button variant='contained' size='large' disabled>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
            </Grid>

            <Typography variant='title3' component='p' style={{ marginTop: 32 }}>Secondary</Typography><br/>
            <Grid container columns={2} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='large'>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='large'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='large' disabled>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='large' disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='large'>
                        Button <CaretDown style={{ marginLeft: 8 }}/>
                    </Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='large'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='large' disabled>
                        Button <CaretDown style={{ marginLeft: 8 }}/>
                    </Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='large' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='large'>
                        <Favorite style={{ marginRight: 8 }}/> Button
                    </Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='large'>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                <Button variant='outlined' color='secondary' size='large' disabled>
                        <Favorite style={{ marginRight: 8 }}/> Button
                    </Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='large' disabled>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
            </Grid>

            <ThemeProvider theme={themeDark}>
                <div style={{ backgroundColor: themeDark.palette.background.default,
                              color: themeDark.palette.background.text, padding: 24,
                              marginTop: 100, marginBottom: 100 }}>
                    <Typography variant='title3' component='p'>Primary</Typography><br/>
                    <Grid container columns={2} spacing={5}>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='large'>Button</Button>
                            <Code code={`<Button variant='contained'
        size='large'>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='large' disabled>Button</Button>
                            <Code code={`<Button variant='contained'
        size='large' disabled>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='large'>
                                Button <CaretDown style={{ marginLeft: 8 }}/>
                            </Button>
                            <Code code={`<Button variant='contained'
        size='large'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='large' disabled>
                                Button <CaretDown style={{ marginLeft: 8 }}/>
                            </Button>
                            <Code code={`<Button variant='contained'
        size='large' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='large'>
                                <Favorite style={{ marginRight: 8 }}/> Button
                            </Button>
                            <Code code={`<Button variant='contained'
        size='large'>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                        <Button variant='contained' size='large' disabled>
                                <Favorite style={{ marginRight: 8 }}/> Button
                            </Button>
                            <Code code={`<Button variant='contained'
        size='large' disabled>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                    </Grid>

                    <Typography variant='title3' component='p' style={{ marginTop: 32 }}>Secondary</Typography><br/>
                    <Grid container columns={2} spacing={5}>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='large'>Button</Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='large'>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='large' disabled>Button</Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='large' disabled>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='large'>
                                Button <CaretDown style={{ marginLeft: 8 }}/>
                            </Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='large'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='large' disabled>
                                Button <CaretDown style={{ marginLeft: 8 }}/>
                            </Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='large' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='large'>
                                <Favorite style={{ marginRight: 8 }}/> Button
                            </Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='large'>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                        <Button variant='outlined' color='secondary' size='large' disabled>
                                <Favorite style={{ marginRight: 8 }}/> Button
                            </Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='large' disabled>
    <Favorite style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                    </Grid>
                </div>
            </ThemeProvider>

            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Buttons <span style={title_}>/ Medium</span>
            </Typography>

            <Typography variant='title3' component='p'>Primary</Typography><br/>
            <Grid container columns={2} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained'>Button</Button>
                    <Code code={`<Button variant='contained'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' disabled>Button</Button>
                    <Code code={`<Button variant='contained' disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained'>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='contained'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' disabled>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='contained' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained'><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='contained'>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' disabled><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='contained' disabled>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
            </Grid>

            <Typography variant='title3' component='p' style={{ marginTop: 32 }}>Secondary</Typography><br/>
            <Grid container columns={2} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary'>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' disabled>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary'>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='outlined' color='secondary'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' disabled>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary'><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' disabled><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        disabled>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
            </Grid>

            <ThemeProvider theme={themeDark}>
                <div style={{ backgroundColor: themeDark.palette.background.default,
                              color: themeDark.palette.background.text, padding: 24,
                              marginTop: 100, marginBottom: 100 }}>
                    <Typography variant='title3' component='p'>Primary</Typography><br/>
                    <Grid container columns={2} spacing={5}>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained'>Button</Button>
                            <Code code={`<Button variant='contained'>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' disabled>Button</Button>
                            <Code code={`<Button variant='contained' disabled>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained'>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                            <Code code={`<Button variant='contained'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' disabled>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                            <Code code={`<Button variant='contained' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained'><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                            <Code code={`<Button variant='contained'>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' disabled><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                            <Code code={`<Button variant='contained' disabled>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                    </Grid>

                    <Typography variant='title3' component='p' style={{ marginTop: 32 }}>Secondary</Typography><br/>
                    <Grid container columns={2} spacing={5}>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                        </Grid>
                        <Grid item xs={1}>
                    <Button variant='outlined' color='secondary'>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' disabled>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary'>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='outlined' color='secondary'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' disabled>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary'><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' disabled><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        disabled>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                    </Grid>
                </div>
            </ThemeProvider>

            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Buttons <span style={title_}>/ Small</span>
            </Typography>

            <Typography variant='title3' component='p'>Primary</Typography><br/>
            <Grid container columns={2} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='small'>Button</Button>
                    <Code code={`<Button variant='contained'
        size='small'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='small' disabled>Button</Button>
                    <Code code={`<Button variant='contained'
        size='small' disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='small'>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='contained'
        size='small'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='small' disabled>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='contained'
        size='small' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='small'><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='contained'
        size='small'>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='contained' size='small' disabled><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='contained'
        size='small' disabled>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
            </Grid>

            <Typography variant='title3' component='p' style={{ marginTop: 32 }}>Secondary</Typography><br/>
            <Grid container columns={2} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='small'>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='small'>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='small' disabled>Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='small' disabled>
    Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='small'>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='small'>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='small' disabled>Button <CaretDown style={{ marginLeft: 8 }}/></Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='small' disabled>
    Button <CaretDown style={{ marginLeft: 8 }}/>
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='small'><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='small'>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='secondary' size='small' disabled><PlayFilled style={{ marginRight: 8 }}/> Button</Button>
                    <Code code={`<Button variant='outlined' color='secondary'
        size='small' disabled>
    <PlayFilled style={{ marginRight: 8 }}/> Button
</Button>`}/>
                </Grid>
            </Grid>

            <ThemeProvider theme={themeDark}>
                <div style={{ backgroundColor: themeDark.palette.background.default,
                              color: themeDark.palette.background.text, padding: 24,
                              marginTop: 100 }}>
                    <Typography variant='title3' component='p'>Primary</Typography><br/>
                    <Grid container columns={2} spacing={5}>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='small'>Button</Button>
                            <Code code={`<Button variant='contained'
        size='small'>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='small' disabled>Button</Button>
                            <Code code={`<Button variant='contained'
        size='small' disabled>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='small'>Button <CaretDown size='s' style={{ marginLeft: 8 }}/></Button>
                            <Code code={`<Button variant='contained'
        size='small'>
    Button <CaretDown size='s' style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='small' disabled>Button <CaretDown size='s' style={{ marginLeft: 8 }}/></Button>
                            <Code code={`<Button variant='contained'
        size='small' disabled>
    Button <CaretDown size='s' style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='small'><PlayFilled size='s' style={{ marginRight: 8 }}/> Button</Button>
                            <Code code={`<Button variant='contained'
        size='small'>
    <PlayFilled size='s' style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' size='small' disabled><PlayFilled size='s' style={{ marginRight: 8 }}/> Button</Button>
                            <Code code={`<Button variant='contained'
        size='small' disabled>
    <PlayFilled size='s' style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                    </Grid>

                    <Typography variant='title3' component='p' style={{ marginTop: 32 }}>Secondary</Typography><br/>
                    <Grid container columns={2} spacing={5}>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Default / Active</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1' component='p' style={label_style}>Disabled</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='small'>Button</Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='small'>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='small' disabled>Button</Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='small' disabled>
    Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='small'>Button <CaretDown size='s' style={{ marginLeft: 8 }}/></Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='small'>
    Button <CaretDown size='s' style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='small' disabled>Button <CaretDown size='s' style={{ marginLeft: 8 }}/></Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='small' disabled>
    Button <CaretDown size='s' style={{ marginLeft: 8 }}/>
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='small'><PlayFilled size='s' style={{ marginRight: 8 }}/> Button</Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='small'>
    <PlayFilled size='s' style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='outlined' color='secondary' size='small' disabled><PlayFilled size='s' style={{ marginRight: 8 }}/> Button</Button>
                            <Code code={`<Button variant='outlined' color='secondary'
        size='small' disabled>
    <PlayFilled size='s' style={{ marginRight: 8 }}/> Button
</Button>`}/>
                        </Grid>
                    </Grid>
                </div>
            </ThemeProvider>

        </Container>
    )
}

export default Buttons
