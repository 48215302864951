import React from 'react'

import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

import {themeLight} from '../theme'

export default function ProductCard({ height }) {
    return (<>
        <Card sx={{ width: '100%', height }} /><br/>
        <Typography variant='subtitle2' component='p'>
            Nike Product Title
        </Typography>
        <Typography variant='body3' component='p' color={themeLight.palette.common.grey1}>
            Product Category<br/>4 Colors
        </Typography><br/>
        <Typography variant='subtitle2' component='p'>
            $100
        </Typography>
        <Typography variant='subtitle2' component='p'
                    style={{ textDecoration: 'line-through' }}
                    color={themeLight.palette.common.grey1}>
            $120
        </Typography>
    </>)
}
