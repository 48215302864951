import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {themeLight, themeDark} from '../theme'

const title_ = {color: themeLight.palette.common.grey1}

function PromoCards() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Promo Cards
            </Typography>
            <div style={{ height: 300, position: 'relative', marginTop: 30 }}>
                <div style={{ position: 'absolute', width: 100, height: 150,
                                left: 24, top: 100,
                                backgroundColor: '#2EA4FF70',
                                transform: 'rotate(-15deg)' }}/>
                <div style={{ position: 'absolute', width: 100, height: 150,
                                left: 70, top: 40,
                                background: 'rgba(0, 144, 255, 0.24)',
                                transform: 'rotate(150deg)' }}/>
                <div style={{ position: 'absolute', width: 150, height: 190,
                                left: 140, top: 20,
                                backgroundColor: '#2EA4FF70',
                                transform: 'rotate(15deg)' }}/>
                <div style={{ position: 'absolute', width: 120, height: 200,
                                left: 220, top: 75,
                                backgroundColor: '#2EA4FF70',
                                transform: 'rotate(-105deg)' }}/>
            </div>
            <p style={{ width: '64%' }}>
                Promo cards are used to display all of our Nike related product campaign imagery. They are the cover for every Nike story, campaign and highlight and are the first selling point to every consumer that uses our apps.
            </p>
            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Product Wall <span style={title_}>/ Desktop Promo Card</span>
            </Typography>

            <Grid container columns={2} spacing={20}>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='title4' component='h4'>
                                Title Headline Copy
                            </Typography><br/>
                            <Typography variant='subtitle1' component='p'>
                                Subtitle copy here
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained'>Lorem</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ display: 'flex', alignItems: 'center' }}>
                        <CardContent>
                        <Typography variant='title4' component='h4'>
                                Title Headline Copy
                            </Typography><br/>
                            <Typography variant='subtitle1' component='p'>
                                Subtitle copy here
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained'>Lorem</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='display4' component='h4'>
                                DISPLAY HEADLINE COPY
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Subtitle copy here
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained'>Lorem</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ display: 'flex', alignItems: 'center' }}>
                        <CardContent>
                            <Typography variant='display4' component='h4'>
                                DISPLAY HEADLINE COPY
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Subtitle copy here
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained'>Lorem</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Container>
    )
}

export default PromoCards
