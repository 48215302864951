import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {themeLight} from '../theme'
const colors = themeLight.palette.common

const list = { backgroundColor: '#2EA4FF', display: 'inline-block',
                width: 120, height: 100, margin: 12 }
const title_ = {color: colors.grey1}
const item = {
    backgroundColor: colors.white,
    padding: '16px 24px 8px 24px',
}
const table_item = {
    backgroundColor: colors.white,
    padding: '24px 24px 0 24px',
}
const text_item = {
    backgroundColor: colors.white,
    padding: '12px 24px',
}
const image = {
    backgroundColor: colors.grey2,
    width: 100, height: 100,
    borderRadius: 4,
    float: 'left',
    marginRight: 12,
}

function ImageItem() {
    return (
        <div style={ item }>
            <div style={ image }/>
            <Typography variant='body3' color={ colors.grey2} component='p'>
                Eyebrow Lorem Ipsum
            </Typography>
            <Typography variant='subtitle1' component='p'>
                Title lorem ipsum dolor sit amet consectetur adipiscing
            </Typography>
            <Typography variant='body3' color={ colors.grey2} component='p'>
                Subtitle Lorem Ipsum
            </Typography>
            <br style={{ clear: 'left' }}/>
        </div>
    )
}

function NumberedItem() {
    return (
        <table style={ item }><tbody>
            <tr><td width={40}>
            <Typography variant='subtitle1'>
                01
            </Typography>
            </td>
            <td>
            <Typography variant='subtitle1' component='p'>
                Lorem ipsum dolor
            </Typography>
            </td></tr>
            <tr><td></td><td>
            <Typography variant='body2' color={ colors.grey2} component='p'>
                Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et. 
            </Typography>
            </td></tr>
        </tbody></table>
    )
}

function EventItem() {
    return (
        <div style={ item }>
            <div style={ image }/>
            <Typography variant='body2' color={ colors.grey2} component='p'>
                Day, Month 6 · 7pm
            </Typography>
            <Typography variant='subtitle1' component='p'>
                Lorem Ipsum Dolor
            </Typography>
            <Typography variant='body3' color={ colors.grey2} component='p'>
                Location<br/>1 Spot Left
            </Typography>
            <br style={{ clear: 'left' }}/>
        </div>
    )
}

function TableItem() {
    return (
        <div style={ table_item }>
            <Typography variant='title5' component='p'>
                Lorem Ipsum<span style={{ float: 'right' }}>&gt;</span>
            </Typography>
            <hr style={{ marginTop: 24, marginBottom: 0, border: '1px solid #E5E5E5' }}/>
        </div>
    )
}

function TextItem() {
    return (
        <div style={ text_item }>
            <Typography variant='body2' color={ colors.grey2} component='p'>
                Lorem Ipsum
            </Typography>
            <Typography variant='subtitle1' component='p'>
                Lorem ipsum dolor sit amet consectetur
            </Typography>
            <Typography variant='body3' color={ colors.grey2} component='p'>
                Lorem Ipsum
            </Typography>
        </div>
    )
}

function Lists() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Lists
            </Typography><br/>

            <div style={list} /><div style={list} /><div style={list} /><div style={list} /><br/>
            <div style={list} /><div style={list} /><div style={list} /><div style={list} /><br/>
            <div style={list} /><div style={list} /><div style={list} /><div style={list} /><br/>
            <div style={list} /><div style={list} /><div style={list} /><div style={list} /><br/>

            <p style={{ width: '64%' }}>
                Lists are a common group of text or images. They are composed of items containing primary and supplemental actions, which are represented by our custom Nike icons and copy.
            </p>

            <hr/>
            <div style={{ backgroundColor: themeLight.palette.common.grey5, padding: 16 }}>
                <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                    Lists <span style={title_}>/ Image</span>
                </Typography>

                <Grid container columns={2} spacing={5}>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Set</Typography><br/>
                        <ImageItem/>
                        <ImageItem/>
                        <ImageItem/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>List Item</Typography><br/>
                        <ImageItem/>
                    </Grid>
                </Grid>
            </div>

            <hr/>
            <div style={{ backgroundColor: themeLight.palette.common.grey5, padding: 16 }}>
                <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                    Lists <span style={title_}>/ Numbered</span>
                </Typography>

                <Grid container columns={2} spacing={5}>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Numbered - Set</Typography><br/>
                        <NumberedItem/>
                        <NumberedItem/>
                        <NumberedItem/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Numbered - List Item</Typography><br/>
                        <NumberedItem/>
                    </Grid>
                </Grid>
            </div>

            <hr/>
            <div style={{ backgroundColor: themeLight.palette.common.grey5, padding: 16 }}>
                <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                    Lists <span style={title_}>/ Event</span>
                </Typography>

                <Grid container columns={2} spacing={5}>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Event Set</Typography><br/>
                        <EventItem/>
                        <EventItem/>
                        <EventItem/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Event - List Item</Typography><br/>
                        <EventItem/>
                    </Grid>
                </Grid>
            </div>

            <hr/>
            <div style={{ backgroundColor: themeLight.palette.common.grey5, padding: 16 }}>
                <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                    Lists <span style={title_}>/ Event</span>
                </Typography>

                <Grid container columns={2} spacing={5}>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Default - Set</Typography><br/>
                        <TableItem/>
                        <TableItem/>
                        <TableItem/>
                        <TableItem/>
                        <TableItem/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Default - List Item</Typography><br/>
                        <TableItem/>
                    </Grid>
                </Grid>
            </div>

            <hr/>
            <div style={{ backgroundColor: themeLight.palette.common.grey5, padding: 16 }}>
                <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                    Lists <span style={title_}>/ Text</span>
                </Typography>

                <Grid container columns={2} spacing={5}>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Text / Set</Typography><br/>
                        <TextItem/>
                        <TextItem/>
                        <TextItem/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography color='#0090FF' component='h5'>Text / Item</Typography><br/>
                        <TextItem/>
                    </Grid>
                </Grid>

                <Typography color='#0090FF' component='h5'>Text / Search Terms</Typography><br/>
                <table style={{ width: '100%' }}><tbody>
                    <tr>
                        <td style={{ backgroundColor: colors.white, padding: 24 }}>
                        <Typography variant='subtitle1' component='h5' color={ colors.grey2}>
                            Popular Search Terms
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        </td>
                        <td style={{ backgroundColor: colors.white, padding: 24 }}>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        <Typography variant='title5' component='p'>
                            Lorem ipsum dolor sit amet
                        </Typography><br/>
                        </td>
                    </tr>
                </tbody></table>
            </div>

        </Container>
    )
}

export default Lists
