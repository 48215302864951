import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import ArrowIcon from '@material-ui/icons/ArrowUpward'

import { Bookmark, CaretLeft, Filter, Inbox, Search } from '@nike/nike-design-system-icons'

import Avatar from '../components/Avatar'
import Code from '../components/Code'

import {themeLight, themeDark} from '../theme'
const colors = themeLight.palette.common

const title_ = {color: colors.grey1}


function NavBar({ name, title, avatar, icons, onDark=false }) {
    const theme = onDark ? themeDark : themeLight
    const style = {backgroundColor: theme.palette.background.default,
                    color: theme.palette.background.text,
                    padding: '10px 24px'}

    return (
        <div style={style}>
            { icons && icons.map((icon, i) =>
                <span style={{ float: 'right', marginLeft: 12 }} key={i}>{icon}</span>).reverse() }
            { avatar ? <Avatar size='xs'/> : <CaretLeft style={{ float: 'left' }}/> }
            { name && <Typography style={{ textAlign: 'center' }}>{ name }</Typography> }
            { title && <br/> }
            { title && <Typography variant='title3'>Title</Typography> }
        </div>
    )
}

function TextDrivers({ title, scrolled=false, labels, onDark=false }) {
    const theme = onDark ? themeDark : themeLight
    const style = {backgroundColor: theme.palette.background.default,
                    color: theme.palette.background.text,
                    padding: '4px 24px'}
    const label_style = { margin: '0 12px 0 24px'}

    if (title)
        return (
            <div style={style}>
                <span style={{ float: 'right' }}>
                { labels.map((label, i) => <span key={i} style={label_style}>{ label }</span>)}
                </span>
                { scrolled
                    ? <Typography variant='subtitle1' >{ title }</Typography>
                    : <Typography style={{ textAlign: 'title4' }}>{ title }</Typography>
                }
            </div>
        )

    style.padding = '10px 24px'
    return (
        <div style={style}>
            { labels.map((label, i) =>
                <span key={i} style={label_style}>{ label }</span>)}
        </div>
    )

}

function LocalNav() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Local Navigation
            </Typography>
            <div style={{ position: 'relative', height: 300, fontSize: 128 }}>
                <ArrowIcon style={{ position: 'absolute', color: '#FE5000',
                    fontSize: 'inherit', transform: 'rotate(45deg)'}}/>
                <ArrowIcon style={{ position: 'absolute', color: '#FE5000',
                    top: 20, left: 160,
                    fontSize: 'inherit', transform: 'rotate(100deg)'}}/>
                <ArrowIcon style={{ position: 'absolute', color: '#FE5000',
                    top: 70, left: 70,
                    fontSize: 'inherit', transform: 'rotate(90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute', color: '#FE5000',
                    top: 130, left: 0,
                    fontSize: 'inherit', transform: 'rotate(-90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute', color: '#FE5000',
                    top: 130, left: 160,
                    fontSize: 'inherit', transform: 'rotate(-45deg)'}}/>
            </div>
            <p style={{ width: '64%' }}>
                Local Navigation and section controls are contextual to the page and promote discovery.
            </p>

            <div style={{ backgroundColor: colors.grey5, padding: 24 }}>
                <Typography variant='title1' component='h1'>
                    Local Navigation <span className={title_}>/ Nav Bar</span>
                </Typography>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>iOS Nav Bar</Typography>
                <hr/>
                <Grid container columns={2} spacing={5}>
                    <Grid item xs={1}>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Default - On Light
                        </Typography>
                        <NavBar name='Lorem' icons={[<Filter/>, <Search/>]}/>
                        <Code code={`
<NavBar name='Lorem'
        icons={[<Filter/>, <Search/>]}/>`}/>

                        <br/>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Avatar - On Light
                        </Typography>
                        <NavBar avatar='avatar' icons={[<Bookmark/>, <Inbox/>]}/>
                        <Code code={`
<NavBar avatar='avatar'
        icons={[<Bookmark/>, <Inbox/>]}/>`}/>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Large Title Default - On Light
                        </Typography>
                        <NavBar name='Lorem' title='Title' icons={[<Filter/>, <Search/>]}/>
                        <Code code={`
<NavBar name='Lorem' title='Title'
        icons={[<Filter/>, <Search/>]}/>
`}/>


<br/>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Large Title Avatar - On Light
                        </Typography>
                        <NavBar avatar='avatar' title='Title' icons={[<Bookmark/>, <Inbox/>, ]}/>
                        <Code code={`
<NavBar avatar='avatar' title='Title'
        icons={[<Bookmark/>, <Inbox/>]}/>
`}/>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Default - On Dark
                        </Typography>
                        <NavBar name='Lorem' icons={[<Filter/>, <Search/>]} onDark={true}/>
                        <Code code={`
<NavBar name='Lorem'
        icons={[<Filter/>, <Search/>]}/>`}/>

                        <br/>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Avatar - On Dark
                        </Typography>
                        <NavBar avatar='avatar' icons={[<Bookmark/>, <Inbox/>]} onDark={true}/>
                        <Code code={`
<NavBar avatar='avatar'
        icons={[<Bookmark/>, <Inbox/>]}/>`}/>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Large Title Default - On Dark
                        </Typography>
                        <NavBar name='Lorem' title='Title' icons={[<Filter/>, <Search/>]} onDark={true}/>
                        <Code code={`
<NavBar name='Lorem' title='Title'
        icons={[<Filter/>, <Search/>]}/>
`}/>

                        <br/>
                        <Typography variant='legal1' color={colors.grey1}>
                            Nav Bar / Large Title Avatar - On Dark
                        </Typography>
                        <NavBar avatar='avatar' title='Title' icons={[<Bookmark/>, <Inbox/>]} onDark={true}/>
                        <Code code={`
<NavBar avatar='avatar' title='Title'
        icons={[<Bookmark/>, <Inbox/>]}/>
`}/>
                    </Grid>
                </Grid>

                <div style={{ height: 100 }}/>
                <Typography variant='title1' component='h1'>
                    Local Navigation <span className={title_}>/ Text Drivers</span>
                </Typography>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Mobile</Typography>
                <hr/>
                <Typography variant='legal1' color={colors.grey1}>
                    Text Drivers / Mobile - On Light
                </Typography>
                <TextDrivers labels={['Driver Label', 'Driver Label', 'Driver Label']}/>
                <Code code={`
<TextDrivers labels={['Driver Label', 'Driver Label', 'Driver Label']}/>
`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Desktop</Typography>
                <hr/>
                <Typography variant='legal1' color={colors.grey1}>
                    Text Drivers / Desktop with Title - On Light
                </Typography>
                <TextDrivers title='Title' labels={['Driver Label', 'Driver Label', 'Driver Label', 'Driver Label', 'Driver Label']}/>
                <Code code={`
<TextDrivers title='Title' labels={['Driver Label', 'Driver Label',
                'Driver Label', 'Driver Label', 'Driver Label']}/>
`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Text Drivers / Desktop with Title - Scrolled - On Light
                </Typography>
                <TextDrivers title='Title' scrolled={true} labels={['Driver Label', 'Driver Label', 'Driver Label', 'Driver Label', 'Driver Label']}/>
                <Code code={`
<TextDrivers title='Title' scrolled={true} labels={['Driver Label', 'Driver Label',
                'Driver Label', 'Driver Label', 'Driver Label']}/>
`}/>

            <div style={{ height: 100 }}/>
                <Typography variant='title1' component='h1'>
                    Local Navigation <span className={title_}>/ Tabs</span>
                </Typography>
                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Tabs</Typography>
                <hr/>
                <Typography variant='legal1' color={colors.grey1}>
                    Tabs / Text Only - On Light
                </Typography>
                <Tabs value={0} aria-label='basic tabs example'
                        TabIndicatorProps={{style: {background:'transparent'}}}>
                    <Tab label='Tab Label' />
                    <Tab label='Tab Label' />
                    <Tab label='Tab Label' />
                </Tabs>
                <Code code={`
<Tabs value={0} aria-label='basic tabs example'
        TabIndicatorProps={{style: {background:'transparent'}}}>
    <Tab label='Tab Label' />
    <Tab label='Tab Label' />
    <Tab label='Tab Label' />
</Tabs>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Tabs / Underline - On Light
                </Typography>
                <Tabs value={0} aria-label='basic tabs example'>
                    <Tab label='Tab Label' />
                    <Tab label='Tab Label' />
                    <Tab label='Tab Label' />
                </Tabs>
                <Code code={`
<Tabs value={0} aria-label='basic tabs example'>
    <Tab label='Tab Label' />
    <Tab label='Tab Label' />
    <Tab label='Tab Label' />
</Tabs>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Tabs / Underline - Keyline - On Light
                </Typography>
                <Tabs value={0} aria-label='basic tabs example'
                        style={{ borderBottom: '1px solid #E5E5E5' }}>
                    <Tab label='Tab Label' />
                    <Tab label='Tab Label' />
                    <Tab label='Tab Label' />
                </Tabs>
                <Code code={`
<Tabs value={0} aria-label='basic tabs example'
        style={{ borderBottom: '1px solid #E5E5E5' }}>
    <Tab label='Tab Label' />
    <Tab label='Tab Label' />
    <Tab label='Tab Label' />
</Tabs>`}/>

            </div>
        </Container>
    )
}

export default LocalNav
