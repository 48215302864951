import { createTheme } from '@material-ui/core'

const palette = {
    black: '#111111',
    white: '#FFFFFF',
    grey1: '#757575',
    grey2: '#CCCCCC',
    grey3: '#E5E5E5',
    grey4: '#F5F5F5',
    grey5: '#FAFAFA',
    green: '#128A09',
    red:   '#D43F21',
}

const typography = {
    fontFamily: '"Helvetica Neue", Helvetica',
    display1: { fontFamily: 'Nike Futura', fontSize: 72, lineHeight: '62px' },
    display2: { fontFamily: 'Nike Futura', fontSize: 56, lineHeight: '48px' },
    display3: { fontFamily: 'Nike Futura', fontSize: 48, lineHeight: '40px' },
    display4: { fontFamily: 'Nike Futura', fontSize: 40, lineHeight: '34px' },
    display5: { fontFamily: 'Nike Futura', fontSize: 24, lineHeight: '22px' },
    title1: { fontSize: 36, lineHeight: '40px' },
    title2: { fontSize: 32, lineHeight: '36px' },
    title3: { fontSize: 28, lineHeight: '32px' },
    title4: { fontSize: 24, lineHeight: '28px' },
    title5: { fontSize: 20, lineHeight: '24px' },
    title6: { fontSize: 18, lineHeight: '24px' },
    subtitle1: { fontSize: 16, lineHeight: '24px' },
    subtitle2: { fontSize: 14, lineHeight: '20px' },
    body1: { fontSize: 20, lineHeight: '28px' },
    body2: { fontSize: 16, lineHeight: '24px' },
    body3: { fontSize: 14, lineHeight: '20px' },
    legal1: { fontSize: 12, lineHeight: '18px' },
    button1: { fontSize: 16, lineHeight: '24px' },
    button2: { fontSize: 14, lineHeight: '20px' },
    button3: { fontSize: 12, lineHeight: '18px' },
}

// https://next.material-ui.com/customization/theme-components/
const components = {
    MuiAccordion: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                borderBottom: '1px solid #E5E5E5',
                '&:before': {
                    display: 'none'
                },
                '&:last-of-type': {
                    borderRadius: 0
                }
            }
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: '16px',
                fontFamily: '"Helvetica Neue", Helvetica',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                textTransform: 'inherit',
            },
            sizeSmall: {
                borderRadius: '24px',
                fontSize: '10px',
            },
            sizeMedium: {
                borderRadius: '30px',
            },
            sizeLarge: {
                borderRadius: '30px',
                width: 327,
                height: 60,
            },
            contained: {
                '&:active': {
                    backgroundColor: palette.grey1
                }
            },
            outlined: {
                borderColor: palette.grey2,
                '&:hover': {
                    backgroundColor: 'inherit',
                    borderColor: 'inherit',
                },
            },
            outlinedSecondary: {
                color: palette.black,
                '&:active': {
                    backgroundColor: palette.white
                },
                '&:hover': {
                    backgroundColor: 'inherit',
                    borderColor: 'inherit',
                },
            }
        }
    },
    MuiCard: {
        styleOverrides: {
            root: {
                backgroundColor: '#AAA',
                color: palette.white,
            }
        }
    },
    MuiSnackbar: {
        styleOverrides: {
            root: {
                borderRadius: 4,
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                fontSize: 16,
                lineHeight: '24px',
            }
        }
    }
}

// https://next.material-ui.com/customization/default-theme/
// https://next.material-ui.com/customization/palette/
const themeLight = createTheme({
    typography,
    components,
    palette: {
        background: {
            default: palette.white,
            paper: palette.grey5,
            //text: palette.black
        },
        primary: {
            main: palette.black,
            contrastText: palette.white
        },
        divider: palette.grey2,
        action: {
            disabled: palette.grey1,
            disabledBackground: palette.grey3,
        },
        common: {
            black: '#111111',
            white: '#FFFFFF',
            grey1: '#757575',
            grey2: '#CCCCCC',
            grey3: '#E5E5E5',
            grey4: '#F5F5F5',
            grey5: '#FAFAFA',
            green: '#128A09',
            red:   '#D43F21',         
        }
    },
})

const darkComponents = JSON.parse(JSON.stringify(components))
darkComponents.MuiButton.styleOverrides.outlinedSecondary = {
    color: palette.white,
    '&:active': {
        //backgroundColor: palette.white
    },
    '&:hover': {
        backgroundColor: 'inherit',
        borderColor: 'inherit',
    },
}

const themeDark = createTheme({
    typography,
    components: darkComponents,
    palette: {
        type: 'dark',
        background: {
            default: palette.black,
            text: palette.white
        },
        primary: {
            main: palette.white,
            //contrastText: palette.white
        },
        action: {
            disabled: palette.white,
            disabledBackground: '#8B8B8B',
        },
        common: {
            black: '#111111',
            white: '#FFFFFF',
            grey1: '#B2B2B2',
            grey2: '#8B8B8B',
            grey3: '#757575',
            grey4: '#3A3A3A',
            grey5: '#222222',
            green: '#41A139',
            red:   '#FF4D29',
        }
    }
})

export { themeLight, themeDark }
