import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuMui from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import ArrowIcon from '@material-ui/icons/ArrowUpward'

import Code from '../components/Code'

import { Activity, Analytics, Bag, Bookmark, Calendar, Club, Discover,
        Favorite, Feed, Home, ImageNoImage, Inbox, Menu, Message, NikeApp,
        Profile, Programs, Run, Runner, Search, Settings, ShopNikeApp, Workouts }
        from '@nike/nike-design-system-icons'

import Avatar from '../components/Avatar'
import {themeLight} from '../theme'
const colors = themeLight.palette.common

const title_ = {color: colors.grey1}
const barDiv = {
    backgroundColor: colors.white,
    padding: '24px 0 8px',
}
const bar = {
    border: '3px solid black',
    borderRadius: '3px',
    width: '35%',
}

function NavBar({ tabs, bottomBar }) {
    const [value, setValue] = useState(0)
    return (<>
        <Tabs value={value} variant='fullWidth'
            style={{ backgroundColor: colors.white }}
            onChange={(event, newValue) => setValue(newValue)}
            TabIndicatorProps={{style: {background:'transparent'}}}>
            { tabs.map((tab, i) => <Tab icon={tab.icon} label={tab.label} key={i} style={{ minWidth: 0 }}/> )}
        </Tabs>
        { bottomBar && <div style={barDiv}><hr style={bar}/></div> }
    </>)
}


function AndroidNavBar({ name, icons, menu=[] }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const style = {backgroundColor: themeLight.palette.background.default,
                    padding: '10px 24px 10px 0'}
    return (
        <div style={style}>
            <Button variant='text' style={{ float: 'left', paddingTop: 2 }}
                onClick={event => setAnchorEl(event.currentTarget)}>
                <Menu/>
            </Button>
            { icons && icons.map((icon, i) =>
                <span style={{ float: 'right', marginLeft: 12 }} key={i}>{icon}</span>).reverse() }
            { name && <Typography>{ name }</Typography> }
            <MenuMui anchorEl={anchorEl} open={Boolean(anchorEl)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    onClose={() => setAnchorEl(null)}>
                <div style={{ padding: 16, fontFamily: '"Helvetica Neue", Helvetica' }}>
                    <Avatar size='l'/><br/>
                    <Typography variant='body2' component='p'>First Last</Typography>
                    <Typography variant='body3' color={colors.grey1} component='p'>City, State</Typography>
                </div>
                { menu.map(item =>
                    <MenuItem>{ item.icon }<span style={{ width: 12 }}/>{ item.label }</MenuItem>
                )}
            </MenuMui>
        </div>
    )
}

function WebNavBar({ name, icons, menu=[] }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const style = {backgroundColor: themeLight.palette.background.default,
                    padding: '10px 24px 10px 0'}
    return (
        <div style={style}>
            <NikeApp style={{ float: 'left' }}/>
            <Button variant='text' style={{ float: 'right', paddingTop: 2 }}
                onClick={event => setAnchorEl(event.currentTarget)}>
                <Menu/>
            </Button>
            { icons && icons.map((icon, i) =>
                <span style={{ float: 'right', marginLeft: 12 }} key={i}>{icon}</span>).reverse() }
            { name && <Typography>{ name }</Typography> }
            <div style={{ clear: 'both' }}/>
            <MenuMui anchorEl={anchorEl} open={Boolean(anchorEl)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    onClose={() => setAnchorEl(null)}>
                <div style={{ padding: 16, fontFamily: '"Helvetica Neue", Helvetica' }}>
                    <Avatar size='l'/><br/>
                    <Typography variant='body2' component='p'>First Last</Typography>
                    <Typography variant='body3' color={colors.grey1} component='p'>City, State</Typography>
                </div>
                { menu.map(item =>
                    <MenuItem>{ item.icon }<span style={{ width: 12 }}/>{ item.label }</MenuItem>
                )}
            </MenuMui>
        </div>
    )
}

function GlobalNav() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Global Navigation
            </Typography>
            <div style={{ position: 'relative', height: 300, fontSize: 96 }}>
                <ArrowIcon style={{ position: 'absolute',
                    top: 0, left: 48,
                    fontSize: 'inherit', transform: 'rotate(90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 0, left: 150,
                    fontSize: 'inherit', transform: 'rotate(90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 48, left: 0,
                    fontSize: 'inherit', transform: 'rotate(-90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 48, left: 100,
                    fontSize: 'inherit', transform: 'rotate(-90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 100, left: 48,
                    fontSize: 'inherit', transform: 'rotate(90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 100, left: 150,
                    fontSize: 'inherit', transform: 'rotate(90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 148, left: 0,
                    fontSize: 'inherit', transform: 'rotate(-90deg)'}}/>
                <ArrowIcon style={{ position: 'absolute',
                    top: 148, left: 100,
                    fontSize: 'inherit', transform: 'rotate(-90deg)'}}/>
            </div>
            <p style={{ width: '64%' }}>
                Global Navigation is persistent across the experience, offering consistency and utility.
            </p>

            <div style={{ backgroundColor: colors.grey5, padding: 24 }}>
                <Typography variant='title1' component='h1'>
                    Global Navigation <span style={title_}>/ iOS</span>
                </Typography>

                <Code code={`import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

function NavBar({ tabs }) {
    const [value, setValue] = useState(0)
    return (
        <Tabs value={value} variant='fullWidth'
            style={{ backgroundColor: colors.white }}
            onChange={(event, newValue) => setValue(newValue)}
            TabIndicatorProps={{style: {background:'transparent'}}}>
            { tabs.map((tab, i) => <Tab icon={tab.icon} label={tab.label} key={i}
                style={{ minWidth: 0 }}/> )}
        </Tabs>
    )
}`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Nav Components</Typography>
                <hr/>
                <Grid container columns={2} spacing={2}>
                    <Grid item xs={1}>
                        <Typography variant='body2' color='#0090FF'>5 Tabs</Typography>
                        <NavBar tabs={[
                            {icon: <Home/>, label: 'Lorem'},
                            {icon: <Home/>, label: 'Label'},
                            {icon: <Home/>, label: 'Label'},
                            {icon: <Home/>, label: 'Label'},
                            {icon: <Home/>, label: 'Label'},
                        ]}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body2' color='#0090FF'>4 Tabs</Typography>
                        <NavBar tabs={[
                            {icon: <Home/>, label: 'Label'},
                            {icon: <Home/>, label: 'Label'},
                            {icon: <Home/>, label: 'Label'},
                            {icon: <Home/>, label: 'Label'},
                        ]}/>
                    </Grid>
                </Grid>
                <Code code={`<NavBar tabs={[
    {icon: <Home/>, label: 'Lorem'},
    {icon: <Home/>, label: 'Label'},
    {icon: <Home/>, label: 'Label'},
    {icon: <Home/>, label: 'Label'},
    {icon: <Home/>, label: 'Label'},
]}/>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Nike App</Typography>
                <hr/>
                <NavBar bottomBar={true}
                    tabs={[
                        {icon: <Home/>, label: 'Home'},
                        {icon: <ShopNikeApp/>, label: 'Shop'},
                        {icon: <Favorite/>, label: 'Favorites'},
                        {icon: <Bag/>, label: 'Bag'},
                        {icon: <Profile/>, label: 'Profile'},
                    ]}/>
                <Code code={`<NavBar bottomBar={true}
    tabs={[
        {icon: <Home/>, label: 'Home'},
        {icon: <ShopNikeApp/>, label: 'Shop'},
        {icon: <Favorite/>, label: 'Favorites'},
        {icon: <Bag/>, label: 'Bag'},
        {icon: <Profile/>, label: 'Profile'},
    ]}/>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>NRC</Typography>
                <hr/>
                <NavBar bottomBar={true}
                    tabs={[
                        {icon: <Feed/>, label: 'Feed'},
                        {icon: <Analytics/>, label: 'Activity'},
                        {icon: <Runner/>, label: 'Run'},
                        {icon: <Club/>, label: 'Club'},
                        {icon: <Run/>, label: 'Shop'},
                    ]}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>NTC</Typography>
                <hr/>
                <NavBar bottomBar={true}
                    tabs={[
                        {icon: <Feed/>, label: 'Feed'},
                        {icon: <Analytics/>, label: 'Activity'},
                        {icon: <Workouts/>, label: 'Workouts'},
                        {icon: <Programs/>, label: 'Programs'},
                        {icon: <Run/>, label: 'Shop'},
                    ]}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>SNKRS</Typography>
                <hr/>
                <NavBar bottomBar={true}
                    tabs={[
                        {icon: <Home/>, label: 'Home'},
                        {icon: <Discover/>, label: 'Discover'},
                        {icon: <Inbox/>, label: 'Inbox'},
                        {icon: <Profile/>, label: 'Profile'},
                    ]}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Nav Items</Typography>
                <hr/>
                <NavBar
                    tabs={[
                        {icon: <Home/>, label: 'Label'},
                        {icon: <Home/>, label: 'Home'},
                        {icon: <ShopNikeApp/>, label: 'Shop'},
                        {icon: <Favorite/>, label: 'Favorites'},
                        {icon: <Bag/>, label: 'Bag'},
                        {icon: <Profile/>, label: 'Profile'},
                        {icon: <Feed/>, label: 'Feed'},
                        {icon: <Analytics/>, label: 'Activity'},
                    ]}/>
                <NavBar
                    tabs={[
                        {icon: <Workouts/>, label: 'Workouts'},
                        {icon: <Programs/>, label: 'Programs'},
                        {icon: <Run/>, label: 'Shop'},
                        {icon: <Discover/>, label: 'Discover'},
                        {icon: <Inbox/>, label: 'Inbox'},
                        {icon: <Runner/>, label: 'Run'},
                        {icon: <Club/>, label: 'Club'},
                    ]}/>

                <hr style={{ margin: '100px 0' }}/>
                <Typography variant='title1' component='h1'>
                    Global Navigation <span style={title_}>/ Android</span>
                </Typography>

                <Typography variant='title3' component='h1' style={{ marginTop: 100 }}>Nav Components</Typography>
                <hr/>
                <div style={{ width: '50%' }}>
                <NavBar tabs={[
                        {icon: <Home/>, label: 'Label'},
                        {icon: <Home/>, label: 'Label'},
                        {icon: <Home/>, label: 'Label'},
                        {icon: <Home/>, label: 'Label'},
                    ]}/>
                </div>
                <div style={{ marginTop: 64, width: '50%' }}>
                    <AndroidNavBar name='Lorem' icons={[ <Search/>, <Bag/> ]}
                        menu={[
                            {icon: <Home/>, label: 'Active'},
                            {icon: <ShopNikeApp/>, label: 'Inactive'},
                            {icon: <ShopNikeApp/>, label: 'Inactive'},
                            {icon: <ShopNikeApp/>, label: 'Inactive'},
                            {icon: <ShopNikeApp/>, label: 'Inactive'},
                            {icon: <ShopNikeApp/>, label: 'Inactive'},
                        ]} />
                </div>

                <Typography variant='title3' component='h1' style={{ marginTop: 100 }}>Team Navs</Typography>
                <hr/>
                <Typography variant='body2' color='#0090FF'>Nike App</Typography>
                <AndroidNavBar name='Discover' icons={[ <Search/>, <Bag/> ]}
                    menu={[
                        {icon: <Discover/>, label: 'Discover'},
                        {icon: <ShopNikeApp/>, label: 'Shop'},
                        {icon: <Favorite/>, label: 'Favorites'},
                        {icon: <Inbox/>, label: 'Inbox'},
                        {icon: <Message/>, label: 'Ask an Expert'},
                        {icon: <Settings/>, label: 'Settings'},
                    ]} />

                <Typography variant='body2' color='#0090FF'>NRC</Typography>
                <AndroidNavBar name='Run' icons={[ <Search/>, <Bag/> ]}
                    menu={[
                        {icon: <Runner/>, label: 'Run'},
                        {icon: <Feed/>, label: 'Feed'},
                        {icon: <Activity/>, label: 'Activity'},
                        {icon: <ImageNoImage/>, label: 'Challenges'},
                        {icon: <Club/>, label: 'Club'},
                        {icon: <Run/>, label: 'Shop'},
                        {icon: <Inbox/>, label: 'Inbox'},
                        {icon: <Settings/>, label: 'Settings'},
                    ]} />

                <Typography variant='body2' color='#0090FF'>NTC</Typography>
                <AndroidNavBar name='Workouts' icons={[ <Search/>, <Bag/> ]}
                    menu={[
                        {icon: <Workouts/>, label: 'Workouts'},
                        {icon: <Programs/>, label: 'Programs'},
                        {icon: <Bookmark/>, label: 'Saved Workouts'},
                        {icon: <Activity/>, label: 'Activity'},
                        {icon: <Feed/>, label: 'Feed'},
                        {icon: <Run/>, label: 'Shop'},
                        {icon: <Calendar/>, label: 'Find Events'},
                        {icon: <Inbox/>, label: 'Inbox'},
                        {icon: <Settings/>, label: 'Settings'},
                    ]} />

                <div style={{ height: 100 }}/>
                <Typography variant='body2' color='#0090FF'>SNKRS</Typography>
                <div style={{ width: '50%' }}>
                <NavBar bottomBar={true}
                    tabs={[
                        {icon: <Home/>, label: 'Home'},
                        {icon: <Discover/>, label: 'Discover'},
                        {icon: <Inbox/>, label: 'Inbox'},
                        {icon: <Profile/>, label: 'Profile'},
                    ]}/>
                </div>

                <hr style={{ margin: '100px 0' }}/>
                <Typography variant='title1' component='h1'>
                    Global Navigation <span style={title_}>/ Web</span>
                </Typography>
                <Typography variant='title3' component='h1' style={{ marginTop: 100 }}>Team Navs</Typography>
                <hr/>
                <WebNavBar icons={[ <Search/>, <Bag/> ]} />
            </div>
        </Container>
    )
}

export default GlobalNav
