import React from 'react'

import MuiAvatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Avatar from '../components/Avatar'
import Code from '../components/Code'

const avatar_style = {alignSelf: 'flex-end'}
const label_style = {color: '#2EA4FF'}

function Avatars() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1'>Avatars</Typography>
            <div style={{ position: 'relative', height: 400, marginTop: 50 }}>
                <MuiAvatar sx={{ width: 150, height: 150, bgcolor: '#979797' }}> </MuiAvatar>
                <MuiAvatar sx={{ width: 70, height: 70, bgcolor: '#111' }}
                    style={{ position: 'absolute', left: 50, top: 200 }}> </MuiAvatar>
                <div style={{ width: 220, height: 220, borderRadius: '50%',
                    background: 'linear-gradient(113.7deg, #FF0015 3.64%, #FE5000 50.92%, #FF6A00 97.26%)',
                    position: 'absolute', left: 150, top: 75 }} />
            </div>

            <p style={{ width: '64%' }}>
                Avatars are customizable design components that let our users upload a preset design or image of themselves. The sizes for our avatars work for all experiences from profiles to user scoreboards.
            </p>
            <hr style={{ margin: 32 }}/>

            <p>
                A custom component was created to support the various sizes
                and the standard background color:
            </p>
            <Code code={`import Avatar from 'components/Avatar'`}/><br/>
            <p style={{ width: '75%' }}>
                Select the size of avatar you require based on the pixel sizes provided below.
            </p>

            <Grid container columns={5} spacing={5}>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>84px</Typography>
                    <div style={{ display: 'flex', height: 100 }}>
                        <Avatar size='xl' style={avatar_style} />
                    </div>
                    <Code code={`<Avatar\n  size='xl'/>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>60px</Typography>
                    <div style={{ display: 'flex', height: 100 }}>
                        <Avatar size='l' style={avatar_style} />
                    </div>
                    <Code code={`<Avatar\n  size='l'/>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>48px</Typography>
                    <div style={{ display: 'flex', height: 100 }}>
                        <Avatar size='m' style={avatar_style} />
                    </div>
                    <Code code={`<Avatar\n  size='m'/>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>36px</Typography>
                    <div style={{ display: 'flex', height: 100 }}>
                        <Avatar size='s' style={avatar_style} />
                    </div>
                    <Code code={`<Avatar\n  size='s'/>`}/>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='subtitle1' component='p' style={label_style}>24px</Typography>
                    <div style={{ display: 'flex', height: 100 }}>
                        <Avatar size='xs' style={avatar_style} />
                    </div>
                    <Code code={`<Avatar\n  size='xs'/>`}/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Avatars
