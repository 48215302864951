import React from 'react'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Code from '../components/Code'

import {themeDark, themeLight} from '../theme'

const useStyles = makeStyles({
    circle: {
        width: 150,
        height: 150,
        borderRadius: '50%',
    },
    header: {
        height: 40,
    },
    line: {
        backgroundColor: themeLight.palette.common.grey2,
        border: 0,
        height: 1,
        marginTop: 24,
        marginBottom: 24,
    }
})

const Accessibility = {
    light: {
        black: 'AAA / 18.8',
        white: 'NA / 1.00',
        grey1: 'AAA / 4.41',
        grey2: 'NA / 1.60',
        grey3: 'NA / 1.25',
        grey4: 'NA / 1.09',
        grey5: 'NA / 1.00',
        green: 'AA / 4.50',
        red: 'AA / 4.63',
    },
    dark: {
        black: 'NA / 1.18',
        white: 'AAA / 15.90',
        grey1: 'AA / 7.50',
        grey2: 'AA / 6.44',
        grey3: 'NA / 3.45',
        grey4: 'NA / 1.39',
        grey5: 'NA / 1.00',
        green: 'AA / 4.83',
        red: 'AA / 4.80',
    }
}

function Item({ name, description, colors, color, accessibility, border=false }) {
    const classes = useStyles()
    const style = { background: colors[color] }
    if (border) style.border = '0.5px solid ' + colors.grey2

    return (
        <Grid item xs={4}>
            <h3>{ name }</h3>
            <Typography variant='body3'>
                <p className={classes.header}>{ description }</p>
                <hr className={classes.line} />
                <p>Hex Code { colors[color] }</p>
                <p>Accessibility { accessibility[color] }</p>
            </Typography>
            <div className={classes.circle} style={style} /><br/>
            <Code code={`{ background:\n  colors.${color} }`}/>
        </Grid>
    )
}

function ColorGrid({ colors, classes, ondark=false }) {
    const accessibility = Accessibility[ondark ? 'dark' : 'light']
    return (<>
        <Grid container spacing={5}>
            <Item name='Black' colors={colors} color='black' border={ondark}
                description='Used for default text, icons and backgrounds.'
                accessibility={ accessibility } />
            <Item name='White' colors={colors} color='white' border={!ondark}
                description='Used for backgrounds and icons.'
                accessibility={ accessibility } />
        </Grid>

        <hr className={classes.line} />

        <Grid container spacing={5}>
            <Item name='Grey 1' colors={colors} color='grey1'
                description='Used for disabled button text and product cards.'
                accessibility={ accessibility } />
            <Item name='Grey 2' colors={colors} color='grey2'
                description='Used for unselected icons and states.'
                accessibility={ accessibility } />
            <Item name='Grey 3' colors={colors} color='grey3'
                description='Used for borders, dividers and keylines.'
                accessibility={ accessibility } />
            <Item name='Grey 4' colors={colors} color='grey4' border={true}
                description='Used for product image backgrounds.'
                accessibility={ accessibility } />
            <Item name='Grey 5' colors={colors} color='grey5' border={true}
                description='Used for product image backgrounds.'
                accessibility={ accessibility } />
        </Grid>

        <hr className={classes.line} />

        <Grid container spacing={5}>
            <Item name='Green' colors={colors} color='green'
                description='Used for success states and confirmations.'
                accessibility={ accessibility } />
            <Item name='Red' colors={colors} color='red'
                description='Used for error states.'
                accessibility={ accessibility } />
        </Grid>
    </>)
}

function Color() {
    const classes = useStyles()

    return (
        <Container maxWidth={false}>
            <div style={{ padding: '0 100px' }}>

                <Typography variant='title1'>Color</Typography>
                <div style={{ position: 'relative', height: 200, marginTop: 50 }}>
                    <div className={classes.circle}
                        style={{ backgroundColor: themeLight.palette.common.black,
                            position: 'absolute', left:0 }} />
                    <div className={classes.circle}
                        style={{ background: 'linear-gradient(113.7deg, #FF0015 3.64%, #FE5000 50.92%, #FF6A00 97.26%)',
                            position: 'absolute', left:60 }} />
                    <div className={classes.circle}
                        style={{ background: '#FF0015',
                            position: 'absolute', left:120 }} />
                    <div className={classes.circle}
                        style={{ background: '#757575',
                            position: 'absolute', left:180 }} />
                </div>

                <p style={{ width: '64%' }}>
                    For Nike, color is always an important factor when designing our digital products. Whether it’s for a card, a button, or an accessible design –  our color system is here to help you make those decisions easier.  
                </p>
                <a target='_blank' rel='noreferrer'
                    href='https://www.figma.com/file/iDYRF013idb4Cu8v5ONvWv/Podium-DS-Guidelines?node-id=0%3A189'>
                    <Button variant='contained'>View Guidelines</Button>
                </a>

                <hr className={classes.line} style={{ marginTop: 60, marginBottom: 60 }} />
                <Typography variant='title1'>Accessibility</Typography>
                <p>Below is our recommendations for point sizes in text and copy
                    based on the latest accessibility standards.</p>
                <Grid container spacing={5}>
                <Grid item xs={6}>
                        <h1>AAA</h1>
                        <Typography variant='body3'>
                            Highest possible accessibility rating. Signifies the most legible experience possible for users.
                            <hr className={classes.line} />
                            Normal Text  4.5<br/>
                            Large Text  3.0 ( 14pt & bold or 18pt+ )
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <h1>AA</h1>
                        <Typography variant='body3'>
                            Second highest accessibility rating. Signifies a very good commitment to accessibility.
                            <hr className={classes.line} />
                            Normal Text  4.5<br/>
                            Large Text  3.0 ( 14pt & bold or 18pt+ )
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <h1>NA</h1>
                        <Typography variant='body3'>
                        Denotes a “non-accessible” color which should not be used for key UI components.
                            <hr className={classes.line} />
                            Normal Text  4.5<br/>
                            Large Text  3.0 ( 14pt & bold or 18pt+ )
                        </Typography>
                    </Grid>
                </Grid>

                <hr className={classes.line} style={{ marginTop: 50, marginBottom: 50 }}/>

                <Typography variant='title2'>On Light</Typography>
                <p>On Light is the starting place for most digital designs at Nike, and is the foundation for all digital design experiences.</p>
                <Code code={`import {themeLight} from '../theme'
const colors = themeLight.palette.common

<ThemeProvider theme={themeLight}>
...
</ThemeProvider`}/>
                <ColorGrid colors={themeLight.palette.common} classes={classes}/>
            </div>

            <ThemeProvider theme={themeDark}>
                <div style={{ backgroundColor: themeDark.palette.background.default,
                                color: themeDark.palette.background.text,
                                marginTop: 100, padding: 100  }}>
                <Typography variant='title2'>On Dark</Typography>
                <p>On Dark is not just an inverse of On Light. Instead, it’s a separate, unique palette for designers and developers.</p>
                <Code code={`import {themeDark} from '../theme'
const colors = themeDark.palette.common

<ThemeProvider theme={themeDark}>
...
</ThemeProvider`}/>
                <ColorGrid colors={themeDark.palette.common} classes={classes} ondark={true}/>
                </div>
            </ThemeProvider>
        </Container>
    )
}

export default Color
