import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {themeLight} from '../theme'
import Code from '../components/Code'
import ProductCard from '../components/ProductCard'

const title_ = {color: themeLight.palette.common.grey1}

function ProductCards() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Product Cards
            </Typography>
            <div style={{ height: 300, position: 'relative', marginTop: 30 }}>
                <div style={{ position: 'absolute', width: 120, height: 160,
                                left: 0, top: 50,
                                backgroundColor: '#FE5000' }}/>
                <div style={{ position: 'absolute', width: 100, height: 130,
                                left: 100, top: 0,
                                backgroundColor: '#FE500080' }}/>
                <div style={{ position: 'absolute', width: 130, height: 170,
                                left: 150, top: 100,
                                backgroundColor: '#FE5000C0' }}/>
                <div style={{ position: 'absolute', width: 100, height: 130,
                                left: 240, top: 50,
                                backgroundColor: '#FE5000' }}/>
            </div>
            <p style={{ width: '64%' }}>
                Product cards are used to display all of our Nike products and related product campaign imagery. They are the cover for every Nike product and are the first selling point to every consumer that uses our apps.
            </p>
            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Product Cards <span style={title_}>/ Mobile</span>
            </Typography>
            <Code code="import ProductCard from '../components/ProductCard'"/><br/>

            <Grid container spacing={20}>
                <Grid item xs={4}>
                    <ProductCard height={240}/>
                </Grid>
            </Grid>
            <Code code='<ProductCard height={240}/>'/>

            <hr style={{ margin: '100px 0px' }}/>

            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <ProductCard height={200}/>
                </Grid>
                <Grid item xs={2}>
                    <ProductCard height={200}/>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={2}>
                    <ProductCard height={200}/>
                </Grid>
            </Grid>

            <hr style={{ margin: '100px 0px' }}/>

            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Product Cards <span style={title_}>/ Desktop</span>
            </Typography>

            <Grid container spacing={10}>
                <Grid item xs={4}>
                    <ProductCard height={300}/>
                </Grid>
                <Grid item xs={3}>
                    <ProductCard height={250}/>
                </Grid>
            </Grid>

            <hr style={{ margin: '100px 0px' }}/>

            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <ProductCard height={300}/>
                </Grid>
                <Grid item xs={4}>
                    <ProductCard height={300}/>
                </Grid>
                <Grid item xs={4}>
                    <ProductCard height={300}/>
                </Grid>
            </Grid>

        </Container>
    )
}

export default ProductCards
