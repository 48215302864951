import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {themeLight, themeDark} from '../theme'

const title_ = {color: themeLight.palette.common.grey1}

function ContentCards() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Content Cards
            </Typography>
            <div style={{ height: 300, position: 'relative', marginTop: 30 }}>
                <div style={{ position: 'absolute', width: 150, height: 200,
                                left: 24,
                                backgroundColor: '#000',
                                transform: 'rotate(-17.13deg)' }}/>
                <div style={{ position: 'absolute', width: 120, height: 150,
                                left: 36, top: 40,
                                backgroundColor: '#F00',
                                transform: 'rotate(30deg)' }}/>
                <div style={{ position: 'absolute', width: 150, height: 200,
                                left: 150, top: 36,
                                backgroundColor: '#C4C4C4',
                                transform: 'rotate(15deg)' }}/>
                <div style={{ position: 'absolute', width: 120, height: 180,
                                left: 230, top: 55,
                                background: 'linear-gradient(113.7deg, #FF0015 3.64%, #FE5000 50.92%, #FF6A00 97.26%)',
                                transform: 'rotate(81.75deg)' }}/>
            </div>
            <p style={{ width: '64%' }}>
                Content cards are used to display all of our Nike products and brand imagery. They are the cover for every Nike digital experience and are the first selling point to every consumer that uses our apps.
            </p>

            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Content <span style={title_}>/ Full-Width Cards</span>
            </Typography>

            <Grid container columns={2} spacing={20}>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='display4' component='h4'>
                                LOREM IPSUM DOLOR SIT AMET CONSECTETUR
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus.
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='title3' component='h4'>
                                Lorem ipsum dolor Sit amet consectetur
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='display4' component='h4'>
                                LOREM IPSUM DOLOR SIT AMET CONSECTETUR
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus.
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 500 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='title3' component='h4'>
                                Lorem ipsum dolor Sit amet consectetur
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Content <span style={title_}>/ Inset Cards</span>
            </Typography>

            <Grid container columns={3} spacing={5}>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 435 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='display4' component='h4'>
                                LOREM IPSUM DOLOR SIT AMET CONSECTETUR
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus.
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 435 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='title3' component='h4'>
                                Lorem ipsum dolor Sit amet consectetur
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 435 }} style={{ position: 'relative' }}>
                    </Card>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='display4' component='h4'>
                                LOREM IPSUM DOLOR SIT AMET CONSECTETUR
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus.
                            </Typography><br/>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                </Grid>

                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 435 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='display4' component='h4'>
                                LOREM IPSUM DOLOR SIT AMET CONSECTETUR
                            </Typography><br/>
                            <Typography variant='body2' component='p'>
                                Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus.
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 435 }} style={{ position: 'relative' }}>
                        <CardContent style={{ position: 'absolute', bottom: 0 }}>
                            <Typography variant='subtitle1' component='p'>
                                Lorem Ipsum
                            </Typography><br/>
                            <Typography variant='title3' component='h4'>
                                Lorem ipsum dolor Sit amet consectetur
                            </Typography><br/>
                            <ThemeProvider theme={themeDark}>
                                <Button variant='contained' style={{ marginRight: 12 }}>Button</Button>
                                <Button variant='outlined' disabled>Button</Button>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ width: '100%', height: 218 }} style={{ position: 'relative' }}>
                    </Card>
                    <Typography variant='subtitle1' color='text.secondary' component='p'>
                        Lorem Ipsum
                    </Typography>
                    <Typography variant='subtitle1' component='h4'>
                        Lorem ipsum dolor Sit amet consectetur
                    </Typography>
                </Grid>
            </Grid>

        </Container>
    )
}

export default ContentCards
