import React from 'react'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import {themeLight} from '../theme'
import Code from '../components/Code'

function Accordions() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Accordion
            </Typography>
            <div style={{backgroundColor: '#7B61FF', height: 75}}/><br/>
            <div style={{backgroundColor: '#7B61FF', height: 183}}/><br/>
            <div style={{backgroundColor: '#7B61FF', height: 71}}/><br/>
            <div style={{backgroundColor: '#7B61FF', height: 153}}/><br/>

            <p style={{ width: '64%' }}>
                An accordion is a lightweight container that can collapse and expand. They can be used for a variety of tasks and can be filled with lists, selection controls or copy.
            </p>
            <hr style={{ margin: '100px 0px' }}/>
            <div style={{ backgroundColor: themeLight.palette.common.grey5 }}>
                <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                    Accordion
                </Typography>

                <Code code={`import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
`}/><br/>

                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Large Set</Typography><br/>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Code code={`<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography variant='title5'>Lorem Ipsum</Typography>
    </AccordionSummary>
</Accordion>`}/>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum (0)</Typography>
                                <span style={{marginLeft: 'auto'}}>
                                    <StarIcon/> <StarIcon/> <StarIcon/> <StarIcon/> <StarBorderIcon/>
                                </span>
                            </AccordionSummary>
                        </Accordion>
                        <Code code={`<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography variant='title5'>
            Lorem Ipsum (0)
        </Typography>
        <span style={{marginLeft: 'auto'}}>
            <StarIcon/> <StarIcon/> <StarIcon/>
            <StarIcon/> <StarBorderIcon/>
        </span>
    </AccordionSummary>
</Accordion>`}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Large Item</Typography><br/>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion><br/>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Rating</Typography><br/>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum (0)</Typography>
                                <span style={{marginLeft: 'auto'}}>
                                <StarIcon/> <StarIcon/> <StarIcon/> <StarIcon/> <StarBorderIcon/>
                                </span>
                            </AccordionSummary>
                        </Accordion>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Expanded</Typography><br/>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant='body2' component='p'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at orci urna. Suspendisse vel libero sit amet velit consectetur cursus. Suspendisse vel libero sit amet velit consectetur cursus.
                                </Typography><br/>
                                <Typography variant='body2' component='p'>
                                    <u>Sed et ipsum quis fringilla.</u>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Code code={`
<Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography variant='title5'>
            Lorem Ipsum
        </Typography>
    </AccordionSummary>
    <AccordionDetails>
        <Typography variant='body2' component='p'>
            Lorem ipsum dolor sit amet,
            consectetur adipiscing elit.
            Nunc at orci urna.
            Suspendisse vel libero sit
            amet velit consectetur cursus.
            Suspendisse vel libero sit amet
            velit consectetur cursus.
        </Typography><br/>
        <Typography variant='body2' component='p'>
            <u>Sed et ipsum quis fringilla.</u>
        </Typography>
    </AccordionDetails>
</Accordion>`}/>
                    </Grid>
                </Grid>

                <hr style={{ margin: '100px 0px' }}/>

                <Grid container spacing={5}>
                    <Grid item xs={4}>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Small Set</Typography><br/>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Small Item</Typography><br/>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                        </Accordion><br/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle1' component='h3' color='#0090FF'>Expanded</Typography><br/>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant='title5'>Lorem Ipsum</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Checkbox/> <Typography variant='button2'>Label</Typography><br/>
                                <Checkbox/> <Typography variant='button2'>Label</Typography><br/>
                                <Checkbox disabled/> <Typography variant='button2' color='#CCC'>Label</Typography><br/>
                                <Checkbox color='secondary'/> <Typography variant='button2'>Label</Typography><br/>
                                <Typography variant='button2' component='p'>&nbsp;&nbsp; + More</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>

            </div>
        </Container>
    )
}

export default Accordions
