import React from 'react'

import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import Code from '../components/Code'

function Row({variant, label, font='Helvetica Neue', weight='Medium', size, height}) {
    return (<>
        <TableRow>
            <TableCell><Typography variant={variant}>{label}</Typography></TableCell>
            <TableCell>{ font }</TableCell>
            <TableCell>{ weight }</TableCell>
            <TableCell>{ size }pt</TableCell>
            <TableCell>{ height }</TableCell>
            <TableCell>0</TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={6} style={{ fontSize: 12 }}>
                <Code code={`<Typography variant='${variant}'>${label}</Typography>`}/>
            </TableCell>
        </TableRow>
    </>)
}

function TypographyPage() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1'>Typography</Typography>
            <div style={{ marginTop: 100 }}>
            <Typography variant='display1' style={{ fontSize: 256 }}>A</Typography>
            <Typography variant='title1' style={{ fontSize: 256 }}>b</Typography>
            </div>
            <div style={{ marginTop: 100, marginBottom: 24 }}>
            <p style={{ width: '64%' }}>
                Typography is one of the most visible foundational elements of our design system. Our Typography system includes multiple sizes, weights, and styles that have different functions based on heirachy and usecase.
            </p>
            </div>

            <pre><a href='https://next.material-ui.com/components/typography/'>Material UI Typography</a></pre>
            <Code code={`import { Typography } from '@material-ui/core'`}/>

                <hr style={{ margin: 24 }}/>
                <Typography variant='title2'>Typography</Typography>
                <p style={{ width: '64%'}}>
                    Typography for Nike digital experiences requires weighing the fonts, weights, sizes and styles for specific scenarios.
                </p>

                <Typography variant='title2' component='h2' style={{ marginTop: 48 }}>
                    Display
                </Typography>
                <Table>
                    <TableRow>
                        <TableCell>Style</TableCell>
                        <TableCell>Font</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Line Height</TableCell>
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                    <Row variant='display1' label='DISPLAY 1' font='Nike Futura ND'
                        weight='Cn XBold' size='72' height='62'/>
                    <Row variant='display2' label='DISPLAY 2' font='Nike Futura ND'
                        weight='Cn XBold' size='56' height='48'/>
                    <Row variant='display3' label='DISPLAY 3' font='Nike Futura ND'
                        weight='Cn XBold' size='48' height='40'/>
                    <Row variant='display4' label='DISPLAY 4' font='Nike Futura ND'
                        weight='Cn XBold' size='40' height='34'/>
                    <Row variant='display5' label='DISPLAY 5' font='Nike Futura ND'
                        weight='Cn XBold' size='24' height='22'/>
                </Table>

                <Typography variant='title2' component='h2' style={{ marginTop: 48 }}>
                    Title
                </Typography>
                <Table style={{ fontSize: 12 }}>
                    <TableRow>
                        <TableCell>Style</TableCell>
                        <TableCell>Font</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Line Height</TableCell>
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                    <Row variant='title1' label='Title 1' size='36' height='40'/>
                    <Row variant='title2' label='Title 2' size='32' height='36'/>
                    <Row variant='title3' label='Title 3' size='28' height='32'/>
                    <Row variant='title4' label='Title 4' size='24' height='28'/>
                    <Row variant='title5' label='Title 5' size='20' height='24'/>
                    <Row variant='title6' label='Title 6' size='18' height='24'/>
                </Table>

                <Typography variant='title2' component='h2' style={{ marginTop: 48 }}>
                    Subtitle
                </Typography>
                <Table style={{ fontSize: 12 }}>
                    <TableRow>
                        <TableCell>Style</TableCell>
                        <TableCell>Font</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Line Height</TableCell>
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                    <Row variant='subtitle1' label='Subtitle 1' size='16' height='24 (1.5)'/>
                    <Row variant='subtitle2' label='Subtitle 2' size='14' height='20'/>
                </Table>

                <Typography variant='title2' component='h2' style={{ marginTop: 48 }}>
                    Body
                </Typography>
                <Table style={{ fontSize: 12 }}>
                    <TableRow>
                        <TableCell>Style</TableCell>
                        <TableCell>Font</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Line Height</TableCell>
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                    <Row variant='body1' label='Body 1' weight='Regular' size='20' height='28 (1.4)'/>
                    <Row variant='body2' label='Body 2' weight='Regular' size='16' height='24 (1.5)'/>
                    <Row variant='body3' label='Body 3' weight='Regular' size='14' height='20 (1.5)'/>
                </Table>

                <Typography variant='title2' component='h2' style={{ marginTop: 48 }}>
                    Legal
                </Typography>
                <Table style={{ fontSize: 12 }}>
                    <TableRow>
                        <TableCell>Style</TableCell>
                        <TableCell>Font</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Line Height</TableCell>
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                    <Row variant='legal1' label='Body 1' weight='Regular' size='12' height='18'/>
                </Table>

                <Typography variant='title2' component='h2' style={{ marginTop: 48 }}>
                    Button
                </Typography>
                <Table style={{ fontSize: 12 }}>
                    <TableRow>
                        <TableCell>Style</TableCell>
                        <TableCell>Font</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Line Height</TableCell>
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                    <Row variant='button1' label='Button 1' size='16' height='24 (1.5)'/>
                    <Row variant='button2' label='Button 2' size='14' height='20'/>
                    <Row variant='button3' label='Button 3' size='12' height='18'/>
                </Table>

        </Container>
    )
}

export default TypographyPage
