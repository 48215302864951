import React, { useState } from 'react'

import { ThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'

import Check from '@material-ui/icons/Check'

import Code from '../components/Code'
import {themeLight, themeDark} from '../theme'
const colors = themeLight.palette.common
const darkColors = themeDark.palette.common

const title_ = {color: colors.grey1}


function SnackBars() {
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [open5, setOpen5] = useState(false)
    const [open6, setOpen6] = useState(false)

    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Snackbars
            </Typography>
            <div style={{ position: 'relative', height: 350 }}>
                <div style={{ position: 'absolute', backgroundColor: '#C4C4C4',
                    width: 150, height: 25, top: 70, left: 20, transform: 'rotate(-15deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#8B8B8B',
                    width: 150, height: 25, top: 120, left: 60, transform: 'rotate(20deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#E5E5E5',
                    width: 150, height: 25, top: 120, left: 60, transform: 'rotate(-15deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#E5E5E5',
                    width: 150, height: 25, top: 200, left: 60, transform: 'rotate(15deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#C4C4C4',
                    width: 150, height: 25, top: 240, left: 0, transform: 'rotate(15deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#3A3A3A',
                    width: 150, height: 25, top: 220, left: 40, transform: 'rotate(-20deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#3A3A3A',
                    width: 150, height: 25, top: 290, left: 60, transform: 'rotate(-5deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#C4C4C4',
                    width: 150, height: 25, top: 80, left: 200, transform: 'rotate(15deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#C4C4C4',
                    width: 150, height: 25, top: 180, left: 180, transform: 'rotate(85deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#E5E5E5',
                    width: 150, height: 25, top: 120, left: 200, transform: 'rotate(-45deg)'}}/>
                <div style={{ position: 'absolute', backgroundColor: '#3A3A3A',
                    width: 150, height: 25, top: 250, left: 250, transform: 'rotate(65deg)'}}/>
            </div>
            <p style={{ width: '64%' }}>
                Snackbars inform users of a process that an app has performed or will perform. They appear temporarily, towards the bottom of the screen. They shouldn’t interrupt the user experience, and they don’t require user input to disappear.
            </p>

            <div style={{ backgroundColor: colors.grey5, padding: 24 }}>
                <Typography variant='title1' component='h1'>
                    Snackbars <span className={title_}>/ On Light</span>
                </Typography>
                <Code code={`import Snackbar from '@material-ui/core/Snackbar'`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>With Icon</Typography>
                <hr/>
                <Button variant='outlined' onClick={() => setOpen1(true)}>Open Snackbar</Button>
                <Snackbar open={open1} autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={() => setOpen1(false)}>
                    <div style={{ padding: '20px 88px', borderRadius: 4,
                                    backgroundColor: colors.black, color: colors.white }}>
                        <Check/> <span style={{ verticalAlign: 'super' }}>Lorem ipsum dolor</span>
                    </div>
                </Snackbar>
                <Code code={`
<Snackbar open={open1} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setOpen1(false)}>
    <div style={{ padding: '20px 88px', borderRadius: 4,
                    backgroundColor: colors.black, color: colors.white }}>
        <Check/> <span style={{ verticalAlign: 'super' }}>Lorem ipsum dolor</span>
    </div>
</Snackbar>
`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>With CTA</Typography>
                <hr/>
                <Button variant='outlined' onClick={() => setOpen2(true)}>Open Snackbar</Button>
                <Snackbar open={open2} autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={() => setOpen2(false)}>
                    <div style={{ padding: '20px 88px', borderRadius: 4,
                                    backgroundColor: colors.black, color: colors.white }}>
                        Lorem ipsum dolor
                        <a href='/snackbars' style={{ marginLeft: 16, color: colors.white }}>Text link</a>
                    </div>
                </Snackbar>
                <Code code={`
<Snackbar open={open2} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setOpen2(false)}>
    <div style={{ padding: '20px 88px', borderRadius: 4,
                    backgroundColor: colors.black, color: colors.white }}>
        Lorem ipsum dolor
        <a href='/snackbars' style={{ marginLeft: 16, color: colors.white }}>Text link</a>
    </div>
</Snackbar>
`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Text Only</Typography>
                <hr/>
                <Button variant='outlined' onClick={() => setOpen3(true)}>Open Snackbar</Button>
                <Snackbar open={open3} autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={() => setOpen3(false)}>
                    <div style={{ padding: '20px 88px', borderRadius: 4,
                                    backgroundColor: colors.black, color: colors.white }}>
                        lorem ipsum dolor sit amet consectetur
                    </div>
                </Snackbar>
                <Code code={`
<Snackbar open={open3} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setOpen3(false)}>
    <div style={{ padding: '20px 88px', borderRadius: 4,
                    backgroundColor: colors.black, color: colors.white }}>
        lorem ipsum dolor sit amet consectetur
    </div>
</Snackbar>
`}/>
                <div style={{ height: 100 }}/>
            </div>

            <ThemeProvider theme={themeDark}>
            <div style={{ backgroundColor: themeDark.palette.background.default,
                            color: themeDark.palette.background.text,
                            padding: 24 }}>
                <Typography variant='title1' component='h1'>
                    Snackbars <span style={{ color: darkColors.grey2 }}>/ On Dark</span>
                </Typography>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>With Icon</Typography>
                <hr/>
                <Button variant='outlined' color='secondary' onClick={() => setOpen4(true)}>Open Snackbar</Button>
                <Snackbar open={open4} autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={() => setOpen4(false)}>
                    <div style={{ padding: '20px 88px', borderRadius: 4,
                            backgroundColor: darkColors.white, color: darkColors.black }}>
                        <Check/> <span style={{ verticalAlign: 'super' }}>Lorem ipsum dolor</span>
                    </div>
                </Snackbar>
                <Code code={`
<Snackbar open={open4} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setOpen4(false)}>
    <div style={{ padding: '20px 88px', borderRadius: 4,
            backgroundColor: darkColors.white, color: darkColors.black }}>
        <Check/> <span style={{ verticalAlign: 'super' }}>Lorem ipsum dolor</span>
    </div>
</Snackbar>
`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>With CTA</Typography>
                <hr/>
                <Button variant='outlined' onClick={() => setOpen5(true)}>Open Snackbar</Button>
                <Snackbar open={open5} autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={() => setOpen5(false)}>
                    <div style={{ padding: '20px 88px',  borderRadius: 4,
                            backgroundColor: darkColors.white, color: darkColors.black }}>
                        Lorem ipsum dolor
                        <a href='/snackbars' style={{ marginLeft: 16, color: darkColors.black }}>Text link</a>
                    </div>
                </Snackbar>
                <Code code={`
<Snackbar open={open5} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setOpen5(false)}>
    <div style={{ padding: '20px 88px',  borderRadius: 4,
            backgroundColor: darkColors.white, color: darkColors.black }}>
        Lorem ipsum dolor
        <a href='/snackbars' style={{ marginLeft: 16, color: darkColors.black }}>Text link</a>
    </div>
</Snackbar>
`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Text Only</Typography>
                <hr/>
                <Button variant='outlined' onClick={() => setOpen6(true)}>Open Snackbar</Button>
                <Snackbar open={open6} autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            onClose={() => setOpen6(false)}>
                    <div style={{ padding: '20px 88px',  borderRadius: 4,
                            backgroundColor: darkColors.white, color: darkColors.black }}>
                        lorem ipsum dolor sit amet consectetur
                    </div>
                </Snackbar>
                <Code code={`
<Snackbar open={open6} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setOpen6(false)}>
    <div style={{ padding: '20px 88px',  borderRadius: 4,
            backgroundColor: darkColors.white, color: darkColors.black }}>
        lorem ipsum dolor sit amet consectetur
    </div>
</Snackbar>
`}/>

            </div>
            </ThemeProvider>
        </Container>
    )
}

export default SnackBars
