import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {themeLight} from '../theme'
import Code from '../components/Code'
import Carousel from '../components/Carousel'
import ProductCard from '../components/ProductCard'

const title_ = {color: themeLight.palette.common.grey1}
const slides = {backgroundColor: '#7B61FF', width: '100%', height: '100%'}

function Carousels() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Carousels
            </Typography><br/>
            <Grid container columns={25} spacing={1} style={{ height: 300 }}>
                <Grid item xs={1}><div style={slides}/></Grid>
                <Grid item xs={2}><div style={slides}/></Grid>
                <Grid item xs={3}><div style={slides}/></Grid>
                <Grid item xs={4}><div style={slides}/></Grid>
                <Grid item xs={5}><div style={slides}/></Grid>
                <Grid item xs={6}><div style={slides}/></Grid>
            </Grid>

            <p style={{ width: '64%' }}>
                A carousel is a list of cards that can be shuffled to display different content. Ideally, each card contains a different item the user can browse from left and right. They can add visual interest and reduce clutter.
            </p>

            <p>To use the Carousel component, first install these libraries:</p>
            <Code code={`npm install @material-ui/icons auto-bind react-swipeable`}/>

            <hr/>
            <Typography variant='title1' component='h1' style={{ marginBottom: 36 }}>
                Product <span style={title_}>/ Desktop Carousels</span>
            </Typography>

            <Carousel>
                <ProductCard height={400} />
                <ProductCard height={400} />
                <ProductCard height={400} />
                <ProductCard height={400} />
            </Carousel>
            <Code code={`
import Carousel from '../components/Carousel'
import ProductCard from '../components/ProductCard'

<Carousel>
    <ProductCard height={400} />
    <ProductCard height={400} />
    <ProductCard height={400} />
    <ProductCard height={400} />
</Carousel>
`}/>
        </Container>
    )
}

export default Carousels
