import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
    grid: {
        backgroundSize: '4px 4px',
        backgroundImage: 'linear-gradient(to right, #0090FF 1px, transparent 1px), linear-gradient(to bottom, #0090FF 1px, transparent 1px)',
        width: 301,
        height: 101,
    },
    rectangle: {
        background: '#0090FF44',
        display: 'inline-block',
        marginRight: 12,
    }
})

function Spacing() {
    const classes = useStyles()

    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>Spacing</Typography>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <br/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>
            <div className={classes.rectangle}
                style={{ width: 108, height: 220, marginTop: 50 }}/>

            <p style={{ width: '64%' }}>
                Our spacing units and intervals are important for the content within components, as well as the vertical stacking of components. Spacing units are based off a 4px grid and increase with 12px intervals.
            </p>
            <a target='_blank' rel='noreferrer'
                    href='https://www.figma.com/file/iDYRF013idb4Cu8v5ONvWv/Podium-DS-Guidelines?node-id=0%3A16012'>
                <Button variant='contained'>View Guidelines</Button>
            </a>

            <hr style={{ marginTop: 30, marginBottom: 30 }} />
            <p>Core spacing units are for both mobile and desktop and are based off a 4px grid.</p>

            <h2>4px Grid</h2>
            <div className={ classes.grid } />

            <h2>x12 Intervals</h2>
            <div className={classes.rectangle} style={{ width: 120, height: 120 }}/>
            <div className={classes.rectangle} style={{ width: 84, height: 84 }}/>
            <div className={classes.rectangle} style={{ width: 60, height: 60 }}/>
            <div className={classes.rectangle} style={{ width: 48, height: 48 }}/>
            <div className={classes.rectangle} style={{ width: 36, height: 36 }}/>
            <div className={classes.rectangle} style={{ width: 24, height: 24 }}/>
            <div className={classes.rectangle} style={{ width: 12, height: 12 }}/>
            <div className={classes.rectangle} style={{ width: 4, height: 4 }}/>
        </Container>
    )
}

export default Spacing
