import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import {themeLight} from '../theme'
const colors = themeLight.palette.common

function Ratios() {
    return (
        <Container maxWidth={false}>
            <h1>Ratios</h1>
            <div style={{ position: 'relative', height: 400 }}>
                <div style={{ backgroundColor: colors.grey1,
                        position: 'absolute', bottom:0, left:0,
                        width: 165, height: 96 }} />
                <div style={{ backgroundColor: '#FE5000',
                        position: 'absolute', bottom:50, left:50,
                        width: 284, height: 165 }} />
                <div style={{ backgroundColor: colors.black,
                        position: 'absolute', bottom:100, left:165,
                        width: 354, height: 205 }} />
                <div style={{ backgroundColor: '#FF0015',
                        position: 'absolute', bottom:200, left:280,
                        width: 485, height: 282 }} />
            </div>

            <p>Ratios apply to all card-based content. Choose a size based on what is featured within the cards and where the content is displayed.</p>

            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <h4>16:9</h4>
                    <p>Used for video content and visual navigation.</p>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 160, height: 90 }} />
                </Grid>
                <Grid item xs={3}>
                    <h4>3:2</h4>
                    <p>Used for editorial content cards or horizontal images.</p>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 150, height: 100 }} />
                </Grid>
                <Grid item xs={3}>
                    <h4>1:1</h4>
                    <p>Legacy product image ratio.</p><br/>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 150, height: 150, position: 'relative' }} >
                        <div style={{ background: '#7CC5FF', borderRadius: '50%',
                                    width: 150, height: 150, position: 'absolute' }} />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <h4>4:5</h4>
                    <p>Primary ratio for product images.</p>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 160, height: 200 }} />
                </Grid>
                <Grid item xs={3}>
                    <h4>3:4</h4>
                    <p>Primary ratio for product images.</p>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 150, height: 200 }} />
                </Grid>
                <Grid item xs={3}>
                    <h4>2:3</h4>
                    <p>Primary ratio for product images.</p>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 150, height: 225 }} />
                </Grid>
                <Grid item xs={3}>
                    <h4>9:16</h4>
                    <p>Primary ratio for product images.</p>
                    <div style={{ background: '#C0E3FF', borderRadius: 8,
                                    width: 144, height: 256 }} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Ratios
