import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterList from '@material-ui/icons/FilterList'
import Sort from '@material-ui/icons/Sort'
import Tune from '@material-ui/icons/Tune'

import Code from '../components/Code'
import {themeLight, themeDark} from '../theme'
const colors = themeLight.palette.common
const darkColors = themeDark.palette.common

const title_ = {color: colors.grey1}

function Headers() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1' component='h1'>
                Headers
            </Typography>
            <div style={{backgroundColor: '#2EA4FF', height: 55}}/><br/><br/>
            <div style={{backgroundColor: '#2EA4FF', height: 229}}/><br/><br/>
            <div style={{backgroundColor: '#2EA4FF', height: 84}}/><br/><br/>
            <div style={{backgroundColor: '#2EA4FF', height: 35}}/><br/>

            <p style={{ width: '64%' }}>
                Headers are one of the core components that are used in our Nike digital experiences, from web, to iOS, to content cards and Nike Journal. Use our header library and guidelines to help you design the best digital experience.
            </p>

            <hr style={{ margin: '100px 0px' }}/>
            <div style={{ backgroundColor: colors.grey5, padding: 24 }}>
                <Typography variant='title1' component='h1'>
                    Headers <span class={title_}>/ On Light</span>
                </Typography>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Large</Typography>
                <hr/>
                <Typography variant='legal1' color={colors.grey1}>
                    Desktop / Large - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '4px 48px '}}>
                    <Typography variant='title2'>Title</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '4px 48px '}}>
    <Typography variant='title2'>Title</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Large - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '4px 48px', width: '25%'}}>
                    <Typography variant='title2'>Title</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '4px 48px', width: '25%' }}>
    <Typography variant='title2'>Title</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Large - Eyebrow - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '0 24px ', width: '25%'}}>
                    <Typography variant='body3' component='p'>Eyebrow</Typography>
                    <Typography variant='title3'>
                        Lorem Ipsum, Dolor sit amet consectetur 
                    </Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '0 24px ', width: '25%'}}>
    <Typography variant='body3' component='p'>Eyebrow</Typography>
    <Typography variant='title3'>
        Lorem Ipsum, Dolor sit amet consectetur
    </Typography>
</div>`}/>


                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Large - Caret - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '0 24px ', width: '25%'}}>
                    <Typography variant='title3'>
                        Lorem Ipsum, Dolor sit amet consectetur <ExpandMoreIcon/>
                    </Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '0 24px ', width: '25%'}}>
    <Typography variant='title3'>
        Lorem Ipsum, Dolor sit amet consectetur <ExpandMoreIcon/>
    </Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Medium</Typography>
                <hr/>
                <Typography variant='legal1' color={colors.grey1}>
                    Desktop / Medium with Link - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '8px 48px '}}>
                    <Typography variant='title2'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '8px 48px '}}>
    <Typography variant='title2'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Desktop / Medium with Filters - No Eyebrow - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '4px 48px'}}>
                    <Typography variant='title2'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '4px 48px', width: '25%' }}>
    <Typography variant='title2'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Desktop / Medium with Filters - With Eyebrow - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '0 48px 8px'}}>
                    <Typography variant='subtitle2' component='div'>Eyebrow</Typography>
                    <Typography variant='title4'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '0 48px 8px'}}>
    <Typography variant='subtitle2' component='div'>Eyebrow</Typography>
    <Typography variant='title4'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Medium - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '8px 24px ', width: '25%'}}>
                    <Typography variant='title4'>Title</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '8px 24px ', width: '25%'}}>
    <Typography variant='title4'>Title</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Medium with Link - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '8px 24px ', width: '25%'}}>
                    <Typography variant='title4'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '8px 24px ', width: '25%'}}>
    <Typography variant='title4'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Medium with Eyebrow &amp; Icon - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '32px 24px 8px', width: '25%'}}>
                    <Typography variant='body3' component='div'>Eyebrow</Typography>
                    <Typography variant='title4'>Title</Typography>
                    <Tune style={{ float: 'right' }} />
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '32px 24px 8px', width: '25%'}}>
    <Typography variant='body3' component='div'>Eyebrow</Typography>
    <Typography variant='title4'>Title</Typography>
    <Tune style={{ float: 'right' }} />
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Desktop / Product Title with Price - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '24px 0', width: '25%'}}>
                    <Typography variant='subtitle1' style={{ float: 'right' }}>$100</Typography>
                    <Typography variant='subtitle1'>Eyebrow</Typography>
                    <Typography variant='title3'>Lorem Ipsum</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '24px 0', width: '25%'}}>
    <Typography variant='subtitle1' style={{ float: 'right' }}>$100</Typography>
    <Typography variant='subtitle1'>Eyebrow</Typography>
    <Typography variant='title3'>Lorem Ipsum</Typography>
</div>`}/>

<div style={{ height: 100 }}/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Product Title with Price - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '32px 24px 8px', width: '25%'}}>
                    <Typography variant='body3' style={{ float: 'right' }}>$100</Typography>
                    <Typography variant='body3'>Eyebrow</Typography><br/>
                    <Typography variant='title4'>Lorem Ipsum</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '32px 24px 8px', width: '25%'}}>
    <Typography variant='body3' style={{ float: 'right' }}>$100</Typography>
    <Typography variant='body3'>Eyebrow</Typography><br/>
    <Typography variant='title4'>Lorem Ipsum</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Small</Typography>
                <hr/>
                <Typography variant='legal1' color={colors.grey1}>
                    Mobile / Small with Subtitle &amp; Link - On Light
                </Typography>
                <div style={{ backgroundColor: colors.white, padding: '8px 24px 8px', width: '25%'}}>
                    <Button variant='link' style={{ float: 'right' }}>Text link</Button>
                    <Typography variant='title5'>Title</Typography><br/>
                    <Typography variant='title5' color={colors.grey1}>Subtitle</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: colors.white, padding: '8px 24px 8px', width: '25%'}}>
    <Button variant='link' style={{ float: 'right' }}>Text link</Button>
    <Typography variant='title5'>Title</Typography><br/>
    <Typography variant='title5' color={colors.grey1}>Subtitle</Typography>
</div>`}/>

            </div>

            <hr style={{ margin: '100px 0px' }}/>
            <ThemeProvider theme={themeDark}>
            <div style={{ backgroundColor: themeDark.palette.background.default,
                              color: themeDark.palette.background.text, padding: 24 }}>
                <Typography variant='title1' component='h1'>
                    Headers <span class={title_}>/ On Dark</span>
                </Typography>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Large</Typography>
                <hr/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Desktop / Large - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '4px 48px '}}>
                    <Typography variant='title2'>Title</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '4px 48px '}}>
    <Typography variant='title2'>Title</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Large - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '4px 48px', width: '25%'}}>
                    <Typography variant='title2'>Title</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '4px 48px', width: '25%' }}>
    <Typography variant='title2'>Title</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Large - Eyebrow - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '0 24px ', width: '25%'}}>
                    <Typography variant='body3' component='p'>Eyebrow</Typography>
                    <Typography variant='title3'>
                        Lorem Ipsum, Dolor sit amet consectetur 
                    </Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '0 24px ', width: '25%'}}>
    <Typography variant='body3' component='p'>Eyebrow</Typography>
    <Typography variant='title3'>
        Lorem Ipsum, Dolor sit amet consectetur
    </Typography>
</div>`}/>


                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Large - Caret - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '0 24px ', width: '25%'}}>
                    <Typography variant='title3'>
                        Lorem Ipsum, Dolor sit amet consectetur <ExpandMoreIcon/>
                    </Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '0 24px ', width: '25%'}}>
    <Typography variant='title3'>
        Lorem Ipsum, Dolor sit amet consectetur <ExpandMoreIcon/>
    </Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Medium</Typography>
                <hr/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Desktop / Medium with Link - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '8px 48px '}}>
                    <Typography variant='title2'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '8px 48px '}}>
    <Typography variant='title2'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Desktop / Medium with Filters - No Eyebrow - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '4px 48px'}}>
                    <Typography variant='title2'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '4px 48px', width: '25%' }}>
    <Typography variant='title2'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Desktop / Medium with Filters - With Eyebrow - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '0 48px 8px'}}>
                    <Typography variant='subtitle2' component='div'>Eyebrow</Typography>
                    <Typography variant='title4'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '0 48px 8px'}}>
    <Typography variant='subtitle2' component='div'>Eyebrow</Typography>
    <Typography variant='title4'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Sort <Sort/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>View <ExpandMoreIcon/></Button>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Filter <FilterList/></Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Medium - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '8px 24px ', width: '25%'}}>
                    <Typography variant='title4'>Title</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '8px 24px ', width: '25%'}}>
    <Typography variant='title4'>Title</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Medium with Link - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '8px 24px ', width: '25%'}}>
                    <Typography variant='title4'>Title</Typography>
                    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '8px 24px ', width: '25%'}}>
    <Typography variant='title4'>Title</Typography>
    <Button variant='text' style={{ float: 'right', verticalAlign: 'top' }}>Text link</Button>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Medium with Eyebrow &amp; Icon - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '32px 24px 8px', width: '25%'}}>
                    <Typography variant='body3' component='div'>Eyebrow</Typography>
                    <Typography variant='title4'>Title</Typography>
                    <Tune style={{ float: 'right' }} />
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '32px 24px 8px', width: '25%'}}>
    <Typography variant='body3' component='div'>Eyebrow</Typography>
    <Typography variant='title4'>Title</Typography>
    <Tune style={{ float: 'right' }} />
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Desktop / Product Title with Price - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '24px 0', width: '25%'}}>
                    <Typography variant='subtitle1' style={{ float: 'right' }}>$100</Typography>
                    <Typography variant='subtitle1'>Eyebrow</Typography>
                    <Typography variant='title3'>Lorem Ipsum</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '24px 0', width: '25%'}}>
    <Typography variant='subtitle1' style={{ float: 'right' }}>$100</Typography>
    <Typography variant='subtitle1'>Eyebrow</Typography>
    <Typography variant='title3'>Lorem Ipsum</Typography>
</div>`}/>

<div style={{ height: 100 }}/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Product Title with Price - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '32px 24px 8px', width: '25%'}}>
                    <Typography variant='body3' style={{ float: 'right' }}>$100</Typography>
                    <Typography variant='body3'>Eyebrow</Typography><br/>
                    <Typography variant='title4'>Lorem Ipsum</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '32px 24px 8px', width: '25%'}}>
    <Typography variant='body3' style={{ float: 'right' }}>$100</Typography>
    <Typography variant='body3'>Eyebrow</Typography><br/>
    <Typography variant='title4'>Lorem Ipsum</Typography>
</div>`}/>

                <div style={{ height: 100 }}/>
                <Typography variant='title3' component='h1'>Small</Typography>
                <hr/>
                <Typography variant='legal1' color={darkColors.grey1}>
                    Mobile / Small with Subtitle &amp; Link - On Dark
                </Typography>
                <div style={{ backgroundColor: darkColors.grey5, padding: '8px 24px 8px', width: '25%'}}>
                    <Button variant='link' style={{ float: 'right' }}>Text link</Button>
                    <Typography variant='title5'>Title</Typography><br/>
                    <Typography variant='title5' color={darkColors.grey1}>Subtitle</Typography>
                </div>
                <Code code={`
<div style={{ backgroundColor: darkColors.grey5, padding: '8px 24px 8px', width: '25%'}}>
    <Button variant='link' style={{ float: 'right' }}>Text link</Button>
    <Typography variant='title5'>Title</Typography><br/>
    <Typography variant='title5' color={darkColors.grey1}>Subtitle</Typography>
</div>`}/>

            </div>
            </ThemeProvider>
        </Container>
    )
}

export default Headers
