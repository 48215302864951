import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { Typography } from '@material-ui/core'
import {themeLight} from '../theme'
const colors = themeLight.palette.common

function Radii() {
    return (
        <Container maxWidth={false}>
            <Typography variant='title1'>Radii</Typography>
            <div style={{ position: 'relative', height: 250, marginBottom: 24 }}>
                <div style={{ backgroundColor: '#9797973C',
                        borderRadius: '100px',
                        position: 'absolute', bottom:0, left:0,
                        width: 275, height: 250 }} />
                <div style={{ backgroundColor: '#9797973C',
                        borderRadius: '90px',
                        position: 'absolute', bottom:25, left:25,
                        width: 225, height: 200 }} />
                <div style={{ backgroundColor: '#9797973C',
                        borderRadius: '50px',
                        position: 'absolute', bottom:55, left:55,
                        width: 160, height: 135 }} />
            </div>

            <Typography variant='title3'>Radii measurements should be based on the appropriate design asset, from content display cards to buttons and avatars, our radii library is here to help you make the best choice for your experiences.</Typography>

            <hr style={{ margin: 24 }}/>
            <Typography variant='title3'>Core radii measurements apply to both UI and card-based content.</Typography>
            <Grid container spacing={5} style={{ marginTop: 24 }}>
                <Grid item xs={3}>
                    <Typography variant='title4'>0px</Typography><p/>
                    <Typography variant='subtitle1'>Used for full-width elements such as content display cards.</Typography><p/>
                    <div style={{ background: '#C0E3FF44', borderRadius: 100,
                                    width: 200, height: 200, position: 'absolute' }}>
                        <div style={{ background: colors.white, borderRadius: 100,
                                    width: 80, height: 80, position: 'relative',
                                    left: 60, top: 60 }}/>
                        <div style={{ borderTop: '1px solid #0090FF',
                                    borderRight: '1px solid #0090FF',
                                    width: 100, height: 100, position: 'relative',
                                    left: 0, bottom: -20 }}/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='title4'>4px</Typography><p/>
                    <Typography variant='subtitle1'>Used for small cards and image containers.</Typography><p/>
                    <div style={{ background: '#C0E3FF44', borderRadius: 100,
                                    width: 200, height: 200, position: 'absolute' }}>
                        <div style={{ background: colors.white, borderRadius: 100,
                                    width: 80, height: 80, position: 'relative',
                                    left: 60, top: 60 }}/>
                        <div style={{ borderTop: '1px solid #0090FF',
                                    borderRight: '1px solid #0090FF',
                                    borderTopRightRadius: 4,
                                    width: 100, height: 100, position: 'relative',
                                    left: 0, bottom: -20 }}/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='title4'>8px</Typography><p/>
                    <Typography variant='subtitle1'>Used for large cards and image containers.</Typography><p/>
                    <div style={{ background: '#C0E3FF44', borderRadius: 100,
                                    width: 200, height: 200, position: 'absolute' }}>
                        <div style={{ background: colors.white, borderRadius: 100,
                                    width: 80, height: 80, position: 'relative',
                                    left: 60, top: 60 }}/>
                        <div style={{ borderTop: '1px solid #0090FF',
                                    borderRight: '1px solid #0090FF',
                                    borderTopRightRadius: 8,
                                    width: 100, height: 100, position: 'relative',
                                    left: 0, bottom: -20 }}/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='title4'>Fully Rounded</Typography><p/>
                    <Typography variant='subtitle1'>Used for buttons and avatars.</Typography><p/><br/>
                    <div style={{ background: '#C0E3FF44', borderRadius: 100,
                                    width: 200, height: 200, position: 'absolute' }}>
                        <div style={{ background: colors.white, borderRadius: 100,
                                    width: 80, height: 80, position: 'relative',
                                    left: 60, top: 60 }}/>
                        <div style={{ borderTop: '1px solid #0090FF',
                                    borderRight: '1px solid #0090FF',
                                    borderTopRightRadius: 100,
                                    width: 100, height: 100, position: 'relative',
                                    left: 0, bottom: -20 }}/>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Radii
