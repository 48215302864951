import React from 'react'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import Code from '../components/Code'


function Home() {
    return (
        <Container maxWidth={false}>
            <h1>Podium Design System</h1>
            <p>As specified in <a target='_blank' rel='noreferrer'
                href='https://www.figma.com/file/46yaB0AAWtquq9mDRz3Her/Podium-DS-Library?node-id=20729%3A10431'>Figma</a></p>
            <p>Implemented in <a target='_blank' rel='noreferrer' href='https://next.material-ui.com/'>Material UI version 5</a></p>

            <p>This website is written using React</p>

            <p>
                Most of the components on these pages use basic Material UI components.<br/>
                Some (like Avatars, Cards) are wrappers around Mui components.<br/>
                Others (like Accordion, Button, Card, Snackbar and Tab) override the styles in the Themes.
            </p>

            <p>Most pages in this site have example JSX code in this format:</p>
            <p><Button variant='contained'>Button</Button>
            <Code code={`<Button variant='contained'>Button</Button>`}/>
            </p>

        </Container>
    )
}

export default Home
